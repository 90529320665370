import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
import { isSameDay as isSameDayFns } from "date-fns";
import { GiOfficeChair, GiSandsOfTime } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import {
  FaCalendar,
  FaCalendarDay,
  FaCalendarMinus,
  FaUmbrellaBeach,
  FaUserCheck,
} from "react-icons/fa";
import dayjs from "dayjs";

const Attendance_details = (props) => {
  const employee_page = useSelector((state) => state.Reducer);
  // calendar
  // const [markedDates, setMarkedDates] = useState([
  //   new Date(2023, 8, 5),
  //   new Date(2023, 8, 10),
  // ]);

  // function isSameDay(date1, date2) {
  //   return isSameDayFns(date1, date2);
  // }

  // const tileContent = ({ date }) => {
  //   const isMarked = markedDates.some((markedDate) =>
  //     isSameDay(markedDate, date)
  //   );
  //   return isMarked ? <div className="dot"></div> : null;
  // };

  const [date, setDate] = useState(new Date());
  const [checkin_out, set_checkin_out] = useState();
  const [attendanceData, setAttendanceData] = useState([]);
  
  const onChange = (newDate) => {
    setDate(newDate);
    get_calendar_details(newDate);
    const currentMonth = dayjs(newDate).format("MM");
    const currentYear = dayjs(newDate).format("YYYY");
    setDate(newDate);
    get_calendar_details(newDate);
    get_cards_detail_byid({
      month: currentMonth,
      year: currentYear,
    });
  };

  useEffect(() => {
    get_calendar_details(props.id);
  }, []);

  const get_calendar_details = async (e) => {
    function formatDate(dateString) {
      const date = new Date(dateString);
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(date.getTime() - timezoneOffset);
      return adjustedDate.toISOString().slice(0, 10);
    }

    let formattedDate = formatDate(e || new Date()); // Use today's date if not provided

    if (formattedDate === "1970-01-01") {
      formattedDate = formatDate(new Date());
    }

    try {
      const response = await axiosInstance.post(
        "AttendenceByDateAndId",
        {
          employee_id: props.id,
          attendence_date: formattedDate,
        },
        {
          httpsAgent: employee_page.HTTP_AGENT,
        }
      );

      function sumTotalHours(data) {
        let totalSeconds = 0;
        // if (data[0].total_hours === "") {
        //   set_total_hours("00:00:00");
        //   return;
        // }
        // if (data[0].total_hours === "") {
        //   set_total_hours("00:00:00");
        //   return;
        // }
        data.forEach((item) => {
          const [hours, minutes, seconds] = item.total_hours
            .split(":")
            .map(Number);
          totalSeconds += hours * 3600 + minutes * 60 + seconds;
        });

        const totalHours = Math.floor(totalSeconds / 3600);
        const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
        const totalSecs = totalSeconds % 60;

        const formattedHours = String(totalHours).padStart(2, "0");
        const formattedMinutes = String(totalMinutes).padStart(2, "0");
        const formattedSeconds = String(totalSecs).padStart(2, "0");

        // set_total_hours(
        //   `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        // );
      }

      if (response.data) {
        if (response.data.message === "No attendance data found") {
          set_checkin_out("");
        } else {
          // set_total_hours(response.data[0].total_hours);
          set_checkin_out(response.data);
          setAttendanceData(response.data[0]);
        }
      } else if (response.data.total_hours !== "") {
        sumTotalHours(response.data);
        set_checkin_out(response.data);
      }
    } catch (e) {
      console.error(e.response?.data?.error || e.message);
    }
  };

  // calendar

  // Function to remove seconds
  const removeSeconds = (time) => {
    return time.split(":").slice(0, 2).join(":");
  };
  const [cards_details, set_cards_details] = useState("");

  const get_cards_detail_byid = useCallback(async (e) => {
    try {
      await axiosInstance
        .post(
          "WorkingHoursEmployee",
          {
            employee_id: props.id,
            month: e.month,
            year: e.year,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          const updatedResponseData = {
            ...response.data.Working_Hours_Data,
            monthly_working_hours: removeSeconds(
              response.data.Working_Hours_Data.monthly_working_hours
            ),
            today_working_hours: removeSeconds(
              response.data.Working_Hours_Data.today_working_hours
            ),
            total_working_hours: removeSeconds(
              response.data.Working_Hours_Data.total_working_hours
            ),
            weekly_working_hours: removeSeconds(
              response.data.Working_Hours_Data.weekly_working_hours
            ),
          };
          set_cards_details(updatedResponseData);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  // get cards by id
  useEffect(() => {
    const currentMonth = dayjs().format("MM");
    const currentYear = dayjs().format("YYYY");
    get_cards_detail_byid({
      month: currentMonth,
      year: currentYear,
    });
  }, [get_cards_detail_byid]);

  return (
    <>
      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Attendance Information</h1>
          {/* <p>Update employee Work information</p> */}
        </div>
      </div>
      <div>
        <div className="atten_box">
          <h1>Attendance</h1>
          <div className="atten_det_cardbox">
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <FaCalendarDay style={{ color: "#203E98" }} />
                {/* <GiSandsOfTime/> */}
              </div>
              <p>Today Hour</p>
              <h3 style={{ color: "#008EAD" }}>
                {cards_details ? cards_details.today_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <FaCalendarMinus style={{ color: "#203E98" }} />
                {/* <GiSandsOfTime /> */}
              </div>
              <p>Weekly Hour</p>
              <h3 style={{ color: "#203E98" }}>
                {cards_details ? cards_details.weekly_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <FaCalendar style={{ color: "#8081F5" }} />
                {/* <GiSandsOfTime /> */}
              </div>
              <p>Monthly Hour</p>
              <h3 style={{ color: "#8081F5" }}>
                {cards_details ? cards_details.monthly_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiOfficeChair style={{ color: "#8081F5" }} />
                {/* <GiSandsOfTime /> */}
              </div>
              <p>Total Working Hour</p>
              <h3 style={{ color: "#8081F5" }}>
                {cards_details ? cards_details.total_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <FaUserCheck style={{ color: "#00B2FF" }} />
                {/* <GiSandsOfTime /> */}
              </div>
              <p>Regularization</p>
              <h3 style={{ color: "#00B2FF" }}>
                {cards_details ? cards_details.regularise_count : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <FaUmbrellaBeach style={{ color: "#FF8800" }} />
                {/* <GiSandsOfTime/> */}
              </div>
              <p>Leave</p>
              <h3 style={{ color: "#FF8800" }}>
                {cards_details ? cards_details.leave_count : ""}
              </h3>
            </div>
          </div>
        </div>

        {/* calendar and deatils  */}
        <div className="calendar_details mt-2">
          <div className="calendar">
            <div className="calendar-container">
              <Calendar onChange={onChange} value={date} />
            </div>
          </div>

          <div className="deatils_calendar">
            <div className="at_office">
              <div className="at_office_detail mt-1">
                <p style={{ display: "flex", alignItems: "center" }}>
                  {checkin_out
                    ? checkin_out.attendence?.map((ele, index) => {
                        <>
                          <div className="greendot"></div>

                          <h3>{checkin_out[0]?.checkIn_AtOffice}</h3>
                        </>;
                      })
                    : "N/A"}
                </p>

                <p>
                  Working Hours -{" "}
                  {checkin_out ? checkin_out.total_hours : "N/A"}
                </p>
              </div>
            </div>

            <div className="checkin_checkout mt-1">
              <div className="checkin_checkout_heading">
                <h3>Check In</h3>
                <h3>Check Out</h3>
              </div>

              {checkin_out
                ? checkin_out.attendence?.map((ele, index) => {
                    return (
                      <div className="checkin_checkout_time mt-1" key={index}>
                        <p>
                          {ele.is_regularize ? (
                            <div className="orangedot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkIn_AtOffice === "Worked Remotely" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkIn_AtOffice === "At office" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}{" "}
                          &nbsp;
                          {ele.check_In ? ele.check_In : "No Check In"}
                        </p>
                        <p>
                          {ele.checkOut_AtOffice === "Worked Remotely" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkOut_AtOffice === "At office" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          &nbsp;
                          {ele.check_Out ? ele.check_Out : "No check Out"}
                        </p>
                      </div>
                    );
                  })
                : "No Check In and Out"}
              {checkin_out?.attendence?.length === 0
                ? "No check in check out"
                : null}

              <div className="attendance_color mt-2">
                {checkin_out?.checkIn_AtOffice === "At office" && (
                  <div className="colors_detail">
                    <div className="greendot"></div>
                    <p>At Office</p>
                  </div>
                )}

                {checkin_out?.is_regularize === "1" ? (
                  <div className="colors_detail">
                    <div className="orangedot"></div>
                    <p>Attendance Regularization</p>
                  </div>
                ) : (
                  <div className="colors_detail">
                    {/* <div className="browndot"></div>
                    <p>Worked Remotely</p> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* calendar and deatils  */}
      </div>
    </>
  );
};

export default Attendance_details;
