import React, { useRef, useState } from "react";
import Navbar from "../Navbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaUser } from "react-icons/fa";
import { BiUpload } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

const profileinformation = [
  { title: 'Name', decription: 'Nisha Singh' },
  { title: 'Role', decription: 'Admin' },
  { title: 'Email', decription: 'nishasingh@vmstechs.com' },
  { title: 'Phone Number', decription: '9773957510' },
]

const Profile = () => {

  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef();
  const { t } = useTranslation();

  const signUpSchema = Yup.object({
    contact_profile: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
    // employee_id: Yup.number().required("Enter Employee ID"),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    phone_number: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    email: Yup.string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),
    address: Yup.string().required("Enter Address"),
    city: Yup.string().required("Enter City"),
    country: Yup.string().required("Enter Country"),
    pincode: Yup.string()
      .required("Enter Pin Code")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 6 Digit pincode")
      .max(10, "Enter your 6 Digit pincode"),
    password: Yup
      .string()
      .required('Password is required')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
    confirmPassword: Yup
      .string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
  });

  const [initialValues, setInitialValues] = useState({
    employee_id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
    city: "",
    country: "",
    pincode: "",
    password: "",
    contact_profile: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    // By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // add_employee(values);
    },
  });

  return (
    <div className="main-container">
      {/* <MainSidebar /> */}
      <div className="allcontent">
        <Navbar />

        <div className="mainbox">
          <form onSubmit={handleSubmit}>
            <div className="twoBoxesAlign">
              <div className="charts_employee">
                <div className="profile_upload mt-3 card" style={{ paddingTop: '20px;' }}>
                  {selectedImage ? (
                    <img
                      className="set_profile_img_manually"
                      src={selectedImage}
                      alt=""
                    />
                  ) : (
                    <div className="set_profile_img_manually empty_profile_img">
                      <FaUser size="6em" />
                    </div>

                  )}

                  <div className="upload_btn mt-2">
                    <button
                      type="button"
                      className="adminprofilebtn"
                      onClick={(e) => {
                        // e.preventDefault();
                        // console.log("img=",fileInputRef.current)
                        fileInputRef.current.click();
                      }}
                    >
                      <BiUpload />
                    </button>
                    <input
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setSelectedImage(URL.createObjectURL(file));
                        }
                        setFieldValue(
                          "contact_profile",
                          e.currentTarget.files[0]
                        );
                      }}
                      ref={fileInputRef}
                      name="contact_profile"
                      type="file"
                      style={{ display: "none" }}
                    />
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.contact_profile && touched.contact_profile ? (
                        <p className="form-error">{errors.contact_profile}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="card mt-3 profile-information">
                  <h3 className="adprofiletitle">Information</h3>
                  {profileinformation.map((ele, index) => (
                    <div className="profileinfodata">
                      <span>{ele.title}</span>
                      <p>{ele.decription}</p>
                    </div>
                  ))
                  }

                </div>
              </div>
              <div className="content-tabs mt-3 card">
                <h3 className="adprofiletitle">Basic Info.</h3>
                <div style={{ display: "flex" }}>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="first_name"
                      type="text"
                      value={values.first_name}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="first_name"
                      label="First Name*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.first_name && touched.first_name ? (
                        <p className="form-error">{errors.first_name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="last_name"
                      type="text"
                      value={values.last_name}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      defaultValue='Nisha'
                      id="last_name"
                      label="Last Name*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.last_name && touched.last_name ? (
                        <p className="form-error">{errors.last_name}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="phone_number"
                      type="text"
                      value={values.phone_number}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                        e.target.value = e.target.value.slice(0, 10);
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="phone_number"
                      label="Phone Number*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.phone_number && touched.phone_number ? (
                        <p className="form-error">{errors.phone_number}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="input-container mt-2" style={{ flex: "1" }}>
                    <TextField
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="outlined-basic"
                      label="Email*"
                      variant="outlined"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <h3 className="adprofiletitle">Address Info.</h3>

                <div style={{ display: "flex" }}>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="address"
                      type="text"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="address"
                      label="address*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.address && touched.address ? (
                        <p className="form-error">{errors.address}</p>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="city"
                      type="text"
                      value={values.city}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="city"
                      label="City*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.city && touched.city ? (
                        <p className="form-error">{errors.city}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="country"
                      type="text"
                      value={values.country}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="country"
                      label="Country*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.country && touched.country ? (
                        <p className="form-error">{errors.country}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="input-container mt-2" style={{ flex: "1" }}>
                    <TextField
                      name="pincode"
                      type="text"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                        e.target.value = e.target.value.slice(0, 6);
                      }}
                      value={values.pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="pincode"
                      label="Pin Code*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.pincode && touched.pincode ? (
                        <p className="form-error">{errors.pincode}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <h3 className="adprofiletitle">Password</h3>
                <div style={{ display: "flex" }}>
                  <div
                    className="input-container mr-1 mt-2"
                    style={{ flex: "1" }}
                  >
                    <TextField
                      name="password"
                      type="text"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="password"
                      label="Password*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.password && touched.password ? (
                        <p className="form-error">{errors.password}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="input-container mt-2" style={{ flex: "1" }}>
                    <TextField
                      name="confirmPassword"
                      type="text"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="password"
                      label="Confirm Password*"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p className="form-error">{errors.confirmPassword}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: "right" }}>
                  <button type="submit" className="cusbtn">
                    Update
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default Profile;
