import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  FaBusinessTime,
  FaInfoCircle,
  FaRupeeSign,
  FaUsers,
} from "react-icons/fa";
import { CgCloseR } from "react-icons/cg";
import { BiMenuAltRight } from "react-icons/bi";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";

// add Shift Start
const Edit_shift = (props) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setinitialValues] = useState({
    shiftname: "",
    shifthour: "",
    shiftstarttime: "",
    shiftstartend: "",
    selectedDays: [],
    selectedOption: "",
    checkinby: "",
    checkoutby: "",
    selectedOption_allowance: "",
    allowance_rate: "",
    hours_computation: "",
    min_hours_halfday: "",
    min_hours_fullday: "",
  });
  const week = [
    { shortName: "M", fullName: "Monday" },
    { shortName: "T", fullName: "Tuesday" },
    { shortName: "W", fullName: "Wednesday" },
    { shortName: "T", fullName: "Thursday" },
    { shortName: "F", fullName: "Friday" },
    { shortName: "S", fullName: "Saturday" },
    { shortName: "S", fullName: "Sunday" },
  ];

  const handleDayChange = (day) => {
    const selectedDays = values.selectedDays.includes(day.fullName)
      ? values.selectedDays.filter(
          (selectedDay) => selectedDay !== day.fullName
        )
      : [...values.selectedDays, day.fullName];

    setFieldValue("selectedDays", selectedDays);
  };
  //  day select
  const { t } = useTranslation();
  const shift_redux = useSelector((state) => state.Reducer);

  // get single branch
  useEffect(() => {
    // console.log("the api will be called");
    get_single_shift();
  }, []);
  async function get_single_shift() {
    try {
      await axiosInstance
        .post(`ShiftById`, {
          shift_id: props.shiftId,
          company_id: shift_redux.COMPANYAPI,
        })
        .then((response) => {
          setinitialValues({
            shiftname: response.data[0].shift_name,
            shifthour: response.data[0].shift_hours,
            shiftstarttime: response.data[0].start_time,
            shiftstartend: response.data[0].end_time,
            selectedDays: response.data[0].off_days,
            selectedOption: response.data[0].margin_allowed,
            checkinby: response.data[0].margin_start,
            checkoutby: response.data[0].margin_end,
            selectedOption_allowance: response.data[0].shift_allowance,
            allowance_rate: response.data[0].rate,
            hours_computation: response.data[0].hours_computation,
            min_hours_fullday: response.data[0].min_hours_fullday,
            min_hours_halfday:response.data[0].min_hours_halfday,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single branch

  // shift pop up
  // add Department popup start
  const signUpSchema = Yup.object({
    shiftname: Yup.string().required("Enter Shift Name"),
    shifthour: Yup.string(),
    shiftstarttime: Yup.string().required("Shift start time required"),
    shiftstartend: Yup.string().required("Shift end time required"),
    selectedDays: Yup.array()
      .min(1, "Select at least one day")
      .required("Select at least one day"),
    selectedOption: Yup.boolean(),
    checkinby: Yup.string().test(
      "conditionalRequired",
      "Check in late by is Required",
      function (value) {
        const { selectedOption } = this.parent; // Access other fields in the form

        if (selectedOption) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    checkoutby: Yup.string().test(
      "conditionalRequired",
      "Check out late by is Required",
      function (value) {
        const { selectedOption } = this.parent; // Access other fields in the form

        if (selectedOption) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    allowance_rate: Yup.string().test(
      "conditionalRequired",
      "Allowance rate is required",
      function (value) {
        const { selectedOption_allowance } = this.parent; // Access other fields in the form

        if (selectedOption_allowance) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    hours_computation: Yup.string().required("Required"),
    min_hours_halfday: Yup.string().required("Required"),
    min_hours_fullday: Yup.string().required("Required"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      put_shift(values);
    },
  });
  console.log(errors);
  // add shift deatils
  async function put_shift(e) {
    setLoading(true);

    try {
      await axiosInstance
        .put(`EditShift`, {
          shift_name: e.shiftname,
          shift_hours: e.shifthour,
          start_time: e.shiftstarttime,
          end_time: e.shiftstartend,
          shift_margin: e.selectedOption,
          shift_allowance: e.selectedOption_allowance,
          rate: e.allowance_rate,
          margin_start: e.checkinby,
          margin_end: e.checkoutby,
          OffDays: e.selectedDays,
          company_id: shift_redux.COMPANYAPI,
          shift_id: props.shiftId,
          hours_computation: e.hours_computation,
          min_hours_fullday:e.min_hours_fullday,
          min_hours_halfday:e.min_hours_halfday
        })
        .then((response) => {

          if (response.status == 200) {
            props.get_all_shift();
            props.setEdit_shift(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setEdit_shift(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      props.setEdit_shift(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    } finally {
      setLoading(false);
    }
  }
  // add shift deatils

  // margin radio btn
  const handleClick = () => {
    values.selectedOption === "1"
      ? setFieldValue("selectedOption", "0")
      : setFieldValue("selectedOption", "1");
  };
  // allownace radio btn

  const handleClick_allow = () => {
    values.selectedOption_allowance === "1"
      ? setFieldValue("selectedOption_allowance", "0")
      : setFieldValue("selectedOption_allowance", "1");
    // setFieldValue("selectedOption_allowance", !values.selectedOption_allowance);
  };
  // shift pop up
  function calculateShiftDuration(startTime, endTime) {
    console.log("hello");
    console.log(startTime);

    if (!startTime || !endTime) {
      setFieldValue("shifthour", ""); // Reset if either field is empty
      return;
    }

    let start = new Date(`2024-01-01T${startTime}:00`);
    let end = new Date(`2024-01-01T${endTime}:00`);

    // If endTime is earlier in the day, assume it's on the next day
    if (end <= start) {
      end.setDate(end.getDate() + 1);
    }

    const diffMs = end - start; // Difference in milliseconds
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Convert to minutes

    const durationText = `${diffHrs} hr ${diffMins} min`;

    setFieldValue("shifthour", durationText);
    console.log(durationText);
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          name="shiftname"
          value={values.shiftname}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Shift Name*"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.shiftname && touched.shiftname ? (
            <p className="form-error">{errors.shiftname}</p>
          ) : null}
        </div>
      </div>
      <div className="week_heading_shift">
        <h1>{t("Shift Days")}</h1>
      </div>
      <div className="week_days_shift">
        {week.map((ele, index) => (
          <div key={index} className="select_week">
            <input
              type="checkbox"
              id={`day-${index}`}
              name={`day-${index}`}
              value={ele.fullName}
              checked={values.selectedDays.includes(ele.fullName)}
              onChange={() => handleDayChange(ele)}
            />
            <label htmlFor={`day-${index}`}>
              <p>{ele.shortName}</p>
            </label>
          </div>
        ))}
      </div>
      <div style={{ height: "20px", textAlign: "left" }}>
        {errors.selectedDays && touched.selectedDays ? (
          <p className="form-error">{errors.selectedDays}</p>
        ) : null}
      </div>
      {/* <div className="input-container mt-2">
        <TextField
          name="shifthour"
          value={values.shifthour}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Shift Duration*"
          variant="outlined"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.shifthour && touched.shifthour ? (
            <p className="form-error">{errors.shifthour}</p>
          ) : null}
        </div>
      </div> */}
      <div className="shift_input_boxes">
        <div className="input-container mr-1 mt-2">
          <TextField
            name="shiftstarttime"
            type="time"
            value={values.shiftstarttime}
            onChange={(e) => {
              handleChange(e);
              calculateShiftDuration(e.target.value, values.shiftstartend);
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Start Time*"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.shiftstarttime && touched.shiftstarttime ? (
              <p className="form-error">{errors.shiftstarttime}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="shiftstartend"
            type="time"
            value={values.shiftstartend}
            onChange={(e) => {
              handleChange(e);
              calculateShiftDuration(values.shiftstarttime, e.target.value);
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="End Time*"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.shiftstartend && touched.shiftstartend ? (
              <p className="form-error">{errors.shiftstartend}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="input-container mt-2">
        <div
          className="shift_input_boxes"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="week_heading_shift">
            <h1>{t("Shift Margin")}</h1>
          </div>
          <div className="shift_input_boxes">
            <div className="radio_btn" style={{ marginLeft: "0" }}>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="1"
                  checked={values.selectedOption === "1"}
                  onChange={handleClick}
                />
                <span>Enable</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="0"
                  checked={values.selectedOption === "0"}
                  onChange={handleClick}
                />
                <span>Disable</span>
              </label>
            </div>
          </div>
        </div>
        {values.selectedOption === "1" && (
          <div className="shiftmarginoption">
            <div className="shiftlateby">
              <p className="mb-0">
                Can check in late by
                <input
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                  name="checkinby"
                  className="checklatebybox"
                  value={values.checkinby}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                mintues
              </p>
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.checkinby && touched.checkinby ? (
                  <p className="form-error">{errors.checkinby}</p>
                ) : null}
              </div>
              <p className="mb-0 mt-0">
                Can check out late by
                <input
                  type="text"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                  id="checklateby"
                  className="checklatebybox"
                  name="checkoutby"
                  value={values.checkoutby}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
                minutes
              </p>
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.checkoutby && touched.checkoutby ? (
                  <p className="form-error">{errors.checkoutby}</p>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="input-container mt-2 mb-2">
        <div
          className="shift_input_boxes"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="week_heading_shift mb-1">
            <h1>{t("Shift Allowance")}</h1>
          </div>
          <div className="shift_input_boxes">
            <div className="radio_btn" style={{ marginLeft: "0" }}>
              <label>
                <input
                  type="radio"
                  name="selectedOption_allowance"
                  value="1"
                  checked={values.selectedOption_allowance === "1"}
                  onChange={handleClick_allow}
                />
                <span>Enable</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  name="selectedOption_allowance"
                  value="0"
                  checked={values.selectedOption_allowance === "0"}
                  onChange={handleClick_allow}
                />
                <span>Disable</span>
              </label>
            </div>
          </div>
        </div>

        {values.selectedOption_allowance === "1" && (
          <div className="shiftmarginoption">
            <div className="shiftlateby">
              <p>
                Allowance Rate <FaRupeeSign />
                <input
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                  name="allowance_rate"
                  id="checklateby"
                  className="checklatebybox"
                  value={values.allowance_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
                Rupees per hour
              </p>
            </div>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.allowance_rate && touched.allowance_rate ? (
                <p className="form-error">{errors.allowance_rate}</p>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {/* actual hour computation */}
      <div className="input-container mt-2 mb-2">
                    <div
                      className="shift_input_boxes"
                      style={{
                        display: "block",
                      }}
                    >
                      <div className="week_heading_shift mb-1">
                        <h1>{t("Actual Hour Computation")}</h1>
                      </div>
                      <div
                        className="shift_input_boxes"
                        style={{ display: "block" }}
                      >
                        <div className="radio_btn" style={{ marginLeft: "0" }}>
                          <label>
                            <input
                              type="radio"
                              name="hours_computation"
                              value="first_last"
                              checked={
                                values.hours_computation === "first_last"
                              }
                              onChange={handleChange}
                            />
                            <span>
                              Duration between First Check-in and Out.
                            </span>
                          </label>
                        </div>
                        <div className="radio_btn" style={{ marginLeft: "0" }}>
                          <label>
                            <input
                              type="radio"
                              name="hours_computation"
                              value="sum"
                              checked={values.hours_computation === "sum"}
                              onChange={handleChange}
                            />
                            <span>Sum of Check In and Out time.</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="shift_input_boxes"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* minimum hours half/full day */}
                  <div className="input-container mt-2 mb-2">
                    <div
                      className="shift_input_boxes"
                      style={{
                        display: "block",
                      }}
                    >
                      <div className="week_heading_shift mb-1">
                        <h1>{t("Minimum hour Computation")}</h1>
                      </div>
                      <div className="flex" style={{display:"flex",justifyContent:"space-between"}}>
                        {" "}
                        <div className="shiftmarginoption">
                          <div className="shiftlateby">
                            <p>
                              Half day minimum hours
                              <input
                                onInput={(e) => {
                                  e.target.value = (
                                    e.target.value || ""
                                  ).replace(/[^\d]/g, "");
                                }}
                                name="min_hours_halfday"
                                id="checklateby"
                                className="checklatebybox"
                                value={values.min_hours_halfday}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />{" "}
                            </p>
                          </div>
                          {errors.min_hours_halfday &&
                            touched.min_hours_halfday && (
                              <div
                                style={{ height: "20px", textAlign: "left" }}
                              >
                                <p className="form-error">
                                  {errors.min_hours_halfday}
                                </p>
                              </div>
                            )}
                        </div>
                        <div className="shiftmarginoption">
                          <div className="shiftlateby">
                            <p>
                              Full day minimum hours
                              <input
                                onInput={(e) => {
                                  e.target.value = (
                                    e.target.value || ""
                                  ).replace(/[^\d]/g, "");
                                }}
                                name="min_hours_fullday"
                                id="checklateby"
                                className="checklatebybox"
                                value={values.min_hours_fullday}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />{" "}
                            </p>
                          </div>
                          {errors.min_hours_fullday &&
                            touched.min_hours_fullday && (
                              <div
                                style={{ height: "20px", textAlign: "left" }}
                              >
                                <p className="form-error">
                                  {errors.min_hours_fullday}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="shift_input_boxes"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>

      <div className="text-center mb-3">
        <button type="submit" className="cusbtn" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

const AddEmployee = (props) => {
  const [loading, setLoading] = useState(false);
  const shift_redux = useSelector((state) => state.Reducer);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [initialValues, setinitialValues] = useState({
    employee_name: [],
  });

  const signUpSchema = Yup.object({
    employee_name: Yup.array()
      .of(Yup.string())
      .required("Select Employee Name"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("the submit values are:", values);
      employee_put();
      // put_shift(values);
    },
  });

  useEffect(() => {
    // console.log("the api will be called");
    getEmployeeList();
  }, []);
  const [storeEmployeenames, setstoreEmployeenames] = useState([]);

  //function to edit the employee names in shift
  async function employee_put() {
    setLoading(true);
    try {
      await axiosInstance
        .put(
          `ShiftAddEmployee`,
          {
            shiftId: props.shiftId,
            employeeId: selectedIDs,
          },
          {
            headers: {
              Authorization: `Bearer ${shift_redux.TOKEN}`,
            },
            httpsAgent: shift_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            props.get_all_shift();
            props.setEmployee(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            // setShouldRerender(prev => !prev); // Trigger re-render by toggling state
            // setRenderKey(2);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  //function for getting employee name to display in dropdown
  async function getEmployeeList() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`, {
          headers: {
            Authorization: `Bearer ${shift_redux.TOKEN}`,
          },
          httpsAgent: shift_redux.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("tick data", response.data);
          setstoreEmployeenames(response.data);
          const namesarrID = props.shiftemployeeID;
          const matchedvalues = response.data.filter((employee) =>
            namesarrID.includes(employee.id)
          );
          const matchedNames = matchedvalues.map(
            (employee) => `${employee.first_name} ${employee.last_name}`
          );
          setinitialValues({
            employee_name: matchedNames,
          });
          // console.log("the names are::::::::", matchedNames);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  const [selectedIDs, setselectedIDs] = useState([]);

  function handleselectedemployees(e) {
    const { value: selectedNames } = e.target;

    // If 'Select All' is clicked, select or deselect all employees
    if (selectedNames.includes("selectAll")) {
      const allEmployeeNames = storeEmployeenames.map(
        (employee) => `${employee.first_name} ${employee.last_name}`
      );

      if (values.employee_name.length === storeEmployeenames.length) {
        // If all employees are selected, deselect them
        setFieldValue("employee_name", []);
        setselectedIDs([]);
      } else {
        // Select all employees
        setFieldValue("employee_name", allEmployeeNames);
        const allEmployeeIds = storeEmployeenames.map(
          (employee) => employee.id
        );
        setselectedIDs(allEmployeeIds);
      }
    } else {
      // Handle individual employee selection
      const selectedIds = selectedNames.map(
        (selectedName) =>
          storeEmployeenames.find(
            (employee) =>
              `${employee.first_name} ${employee.last_name}` === selectedName
          )?.id
      );
      setselectedIDs(selectedIds);
      setFieldValue("employee_name", selectedNames);
    }
  }

  function toggleSelectAll() {
    const allEmployeeNames = storeEmployeenames.map(
      (employee) => `${employee.first_name} ${employee.last_name}`
    );

    if (values.employee_name.length === storeEmployeenames.length) {
      // If all employees are already selected, deselect them
      setFieldValue("employee_name", []);
      setselectedIDs([]);
    } else {
      // Otherwise, select all employees
      setFieldValue("employee_name", allEmployeeNames);
      const allEmployeeIds = storeEmployeenames.map((employee) => employee.id);
      setselectedIDs(allEmployeeIds);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <FormControl sx={{ width: 425 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Select Employees
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={values.employee_name || []}
            onChange={(e) => {
              handleselectedemployees(e);
            }}
            MenuProps={MenuProps}
            input={<OutlinedInput label="Select Employees" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {/* Select All Option */}
            <MenuItem value="selectAll" onClick={() => toggleSelectAll()}>
              <Checkbox
                checked={
                  values.employee_name.length === storeEmployeenames.length
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>

            {/* Employee Options */}
            {storeEmployeenames
              ? storeEmployeenames.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    value={`${employee.first_name} ${employee.last_name}`}
                  >
                    <Checkbox
                      checked={values.employee_name.includes(
                        `${employee.first_name} ${employee.last_name}`
                      )}
                    />
                    <ListItemText
                      primary={`${employee.first_name} ${employee.last_name}`}
                    />
                  </MenuItem>
                ))
              : "Please select Manager First"}
          </Select>
        </FormControl>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.employeeList && touched.employeeList ? (
            <p className="form-error">{errors.employeeList}</p>
          ) : null}
        </div>
      </div>

      <div className="text-center mb-3">
        <button type="submit" className="cusbtn" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

const Shift = (props) => {
  //  day select
  const week = [
    { shortName: "M", fullName: "Monday" },
    { shortName: "T", fullName: "Tuesday" },
    { shortName: "W", fullName: "Wednesday" },
    { shortName: "T", fullName: "Thursday" },
    { shortName: "F", fullName: "Friday" },
    { shortName: "S", fullName: "Saturday" },
    { shortName: "S", fullName: "Sunday" },
  ];

  const [loading, setLoading] = useState(false);
  const handleDayChange = (day) => {
    const selectedDays = values.selectedDays.includes(day.fullName)
      ? values.selectedDays.filter(
          (selectedDay) => selectedDay !== day.fullName
        )
      : [...values.selectedDays, day.fullName];

    setFieldValue("selectedDays", selectedDays);
  };
  //  day select
  const { t } = useTranslation();
  const shift_redux = useSelector((state) => state.Reducer);
  const [Create_shift_modal, setCreate_shift_modal] = useState(false);
  const [edit_shift, setEdit_shift] = useState(false);
  const [shift, setShift] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [addemployee, setEmployee] = useState(false);
  const [shiftemployeeID, setshiftemployeeID] = useState();
  // get single branch
  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      // console.log("the api will be called");
      get_all_shift();
    }
  }, [props.tabStyle]);

  async function get_all_shift() {
    try {
      await axiosInstance.get(`AllShifts`).then((response) => {
        setShift(response.data);
      });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single branch

  // shift pop up
  const signUpSchema = Yup.object({
    shiftname: Yup.string().required("Enter Shift Name"),
    shifthour: Yup.string(),
    shiftstarttime: Yup.string().required("Shift start time required"),
    shiftstartend: Yup.string().required("Shift end time required"),
    selectedDays: Yup.array()
      .min(1, "Select at least one day")
      .required("Select at least one day"),
    selectedOption: Yup.boolean(),
    checkinby: Yup.string().test(
      "conditionalRequired",
      "Check in late by is Required",
      function (value) {
        const { selectedOption } = this.parent; // Access other fields in the form

        if (selectedOption) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    checkoutby: Yup.string().test(
      "conditionalRequired",
      "Check out late by is Required",
      function (value) {
        const { selectedOption } = this.parent; // Access other fields in the form

        if (selectedOption) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    selectedOption_allowance: Yup.boolean(),
    allowance_rate: Yup.string().test(
      "conditionalRequired",
      "Allowance rate is required",
      function (value) {
        const { selectedOption_allowance } = this.parent; // Access other fields in the form

        if (selectedOption_allowance) {
          return !!value; // Return true if checkinby is required and has a value
        }

        return true; // Return true for non-required case
      }
    ),
    hours_computation: Yup.string().required("Required"),
    min_hours_halfday: Yup.string().required("Required"),
    min_hours_fullday: Yup.string().required("Required"),
 
  });

  const [initialValues, setinitialValues] = useState({
    shiftname: "",
    shifthour: "",
    shiftstarttime: "",
    shiftstartend: "",
    selectedDays: [],
    selectedOption: "0",
    checkinby: "",
    checkoutby: "",
    selectedOption_allowance: "0",
    allowance_rate: "",
    hours_computation: "",
    min_hours_halfday: "",
    min_hours_fullday: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      Add_shift(values);
    },
  });
  // add shift deatils

  async function Add_shift(e) {
    setLoading(true);
    try {
      await axiosInstance
        .post(`shifts`, {
          shift_name: e.shiftname,
          shift_hour: e.shifthour,
          shift_start_time: e.shiftstarttime,
          shift_end_time: e.shiftstartend,
          shift_margin: e.selectedOption,
          shift_allowance: e.selectedOption_allowance,
          rate: e.allowance_rate,
          margin_start: e.checkinby,
          margin_end: e.checkoutby,
          OffDays: e.selectedDays,
          company_id: shift_redux.COMPANYAPI,
          hours_computation: e.hours_computation,
          min_hours_fullday:e.min_hours_fullday,
          min_hours_halfday:e.min_hours_halfday

        })
        .then((response) => {
          if (response.status == 200) {
            get_all_shift();
            setCreate_shift_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setCreate_shift_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      setCreate_shift_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    } finally {
      setLoading(false);
    }
  }
  // add shift deatils

  // margin radio btn
  const handleClick = () => {
    values.selectedOption === "1"
      ? setFieldValue("selectedOption", "0")
      : setFieldValue("selectedOption", "1");
    // setFieldValue("selectedOption", !values.selectedOption);
  };
  // allownace radio btn

  const handleClick_allow = () => {
    values.selectedOption_allowance === "1"
      ? setFieldValue("selectedOption_allowance", "0")
      : setFieldValue("selectedOption_allowance", "1");
    // setFieldValue("selectedOption_allowance", !values.selectedOption_allowance);
  };

  //calculating shift duration
  function calculateShiftDuration(startTime, endTime) {
    console.log("hello");
    console.log(startTime);

    if (!startTime || !endTime) {
      setFieldValue("shifthour", ""); // Reset if either field is empty
      return;
    }

    let start = new Date(`2024-01-01T${startTime}:00`);
    let end = new Date(`2024-01-01T${endTime}:00`);

    // If endTime is earlier in the day, assume it's on the next day
    if (end <= start) {
      end.setDate(end.getDate() + 1);
    }

    const diffMs = end - start; // Difference in milliseconds
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Convert to minutes

    const durationText = `${diffHrs} hr ${diffMins} min`;

    setFieldValue("shifthour", durationText);
    console.log(durationText);
  }

  // shift pop up
  return (
    <>
      {/* Add pop up */}
      {Create_shift_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_shift_modal(!Create_shift_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Shift</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_shift_modal(!Create_shift_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="shiftname"
                    value={values.shiftname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Shift Name*"
                    variant="outlined"
                  />
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.shiftname && touched.shiftname ? (
                      <p className="form-error">{errors.shiftname}</p>
                    ) : null}
                  </div>
                </div>
                <div className="week_heading_shift">
                  <h1>Off Days</h1>
                </div>
                <div className="week_days_shift">
                  {week.map((ele, index) => (
                    <div key={index} className="select_week">
                      <input
                        type="checkbox"
                        id={`day-${index}`}
                        name={`day-${index}`}
                        value={ele.fullName}
                        checked={values.selectedDays.includes(ele.fullName)}
                        onChange={() => handleDayChange(ele)}
                      />
                      <label htmlFor={`day-${index}`}>
                        <p>{ele.shortName}</p>
                      </label>
                    </div>
                  ))}
                </div>
                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.selectedDays && touched.selectedDays ? (
                    <p className="form-error">{errors.selectedDays}</p>
                  ) : null}
                </div>
                {/* <div className="input-container mt-2">
                  <TextField
                    name="shifthour"
                    value={values.shifthour}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Shift Duration*"
                    variant="outlined"
                    type="time"
                    InputLabelProps={{
                      shrink: true, 
                    }}
                  />
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.shifthour && touched.shifthour ? (
                      <p className="form-error">{errors.shifthour}</p>
                    ) : null}
                  </div>
                </div> */}
                <div className="shift_input_boxes">
                  <div className="input-container mr-1 mt-2">
                    <TextField
                      name="shiftstarttime"
                      type="time"
                      value={values.shiftstarttime}
                      onChange={(e) => {
                        handleChange(e);
                        calculateShiftDuration(
                          e.target.value,
                          values.shiftstartend
                        );
                      }}
                      onBlur={handleBlur}
                      className="text_input"
                      id="outlined-basic"
                      label="Start Time*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.shiftstarttime && touched.shiftstarttime ? (
                        <p className="form-error">{errors.shiftstarttime}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-container mt-2">
                    <TextField
                      name="shiftstartend"
                      type="time"
                      value={values.shiftstartend}
                      onChange={(e) => {
                        handleChange(e);
                        calculateShiftDuration(
                          values.shiftstarttime,
                          e.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      className="text_input"
                      id="outlined-basic"
                      label="End Time*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.shiftstartend && touched.shiftstartend ? (
                        <p className="form-error">{errors.shiftstartend}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="input-container mt-2">
                  <div
                    className="shift_input_boxes"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="week_heading_shift mb-1">
                      <h1>
                        {t("Shift Margin")}
                        <Tooltip
                          title="Allows flexibility by providing a buffer time before and after check-in/check-out."
                          placement="top"
                        >
                          <IconButton>
                            <FaInfoCircle size="0.5em" />
                          </IconButton>
                        </Tooltip>
                      </h1>
                    </div>
                    <div className="shift_input_boxes">
                      <div className="radio_btn" style={{ marginLeft: "0" }}>
                        <label>
                          <input
                            type="radio"
                            name="selectedOption"
                            value="1"
                            checked={values.selectedOption === "1"}
                            onChange={handleClick}
                          />
                          <span>Enable</span>
                        </label>
                      </div>
                      <div className="radio_btn">
                        <label>
                          <input
                            type="radio"
                            name="selectedOption"
                            value="0"
                            checked={values.selectedOption === "0"}
                            onChange={handleClick}
                          />
                          <span>Disable</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values.selectedOption === "1" && (
                    <div className="shiftmarginoption">
                      <div className="shiftlateby">
                        <p>
                          Can check in early by
                          <input
                            onInput={(e) => {
                              e.target.value = (e.target.value || "").replace(
                                /[^\d]/g,
                                ""
                              );
                            }}
                            name="checkinby"
                            className="checklatebybox"
                            value={values.checkinby}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          minutes
                        </p>
                        <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.checkinby && touched.checkinby ? (
                            <p className="form-error">{errors.checkinby}</p>
                          ) : null}
                        </div>
                        <p>
                          Can check out late by
                          <input
                            type="text"
                            onInput={(e) => {
                              e.target.value = (e.target.value || "").replace(
                                /[^\d]/g,
                                ""
                              );
                            }}
                            id="checklateby"
                            className="checklatebybox"
                            name="checkoutby"
                            value={values.checkoutby}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                          minutes
                        </p>
                        <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.checkoutby && touched.checkoutby ? (
                            <p className="form-error">{errors.checkoutby}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="input-container mt-2 mb-2">
                  <div
                    className="shift_input_boxes"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="week_heading_shift mb-1">
                      <h1>
                        {t("Shift Allowance")}
                        <Tooltip
                          title="Employees working beyond 9 hours receive additional compensation based on hourly rates."
                          placement="top"
                        >
                          <IconButton>
                            <FaInfoCircle size="0.5em" />
                          </IconButton>
                        </Tooltip>
                      </h1>
                    </div>
                    <div className="shift_input_boxes">
                      <div className="radio_btn" style={{ marginLeft: "0" }}>
                        <label>
                          <input
                            type="radio"
                            name="selectedOption_allowance"
                            value="1"
                            checked={values.selectedOption_allowance === "1"}
                            onChange={handleClick_allow}
                          />
                          <span>Enable</span>
                        </label>
                      </div>
                      <div className="radio_btn">
                        <label>
                          <input
                            type="radio"
                            name="selectedOption_allowance"
                            value="0"
                            checked={values.selectedOption_allowance === "0"}
                            onChange={handleClick_allow}
                          />
                          <span>Disable</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values.selectedOption_allowance === "1" && (
                    <div className="shiftmarginoption">
                      <div className="shiftlateby">
                        <p>
                          Allowance Rate <FaRupeeSign />
                          <input
                            onInput={(e) => {
                              e.target.value = (e.target.value || "").replace(
                                /[^\d]/g,
                                ""
                              );
                            }}
                            name="allowance_rate"
                            id="checklateby"
                            className="checklatebybox"
                            value={values.allowance_rate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                          Rupees per hour
                        </p>
                      </div>
                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.allowance_rate && touched.allowance_rate ? (
                          <p className="form-error">{errors.allowance_rate}</p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {/* actual hour computation */}
                  <div className="input-container mt-2 mb-2">
                    <div
                      className="shift_input_boxes"
                      style={{
                        display: "block",
                      }}
                    >
                      <div className="week_heading_shift mb-1">
                        <h1>{t("Actual Hour Computation")}</h1>
                      </div>
                      <div
                        className="shift_input_boxes"
                        style={{ display: "block" }}
                      >
                        <div className="radio_btn" style={{ marginLeft: "0" }}>
                          <label>
                            <input
                              type="radio"
                              name="hours_computation"
                              value="first_last"
                              checked={
                                values.hours_computation === "first_last"
                              }
                              onChange={handleChange}
                            />
                            <span>
                              Duration between First Check-in and Out.
                            </span>
                          </label>
                        </div>
                        <div className="radio_btn" style={{ marginLeft: "0" }}>
                          <label>
                            <input
                              type="radio"
                              name="hours_computation"
                              value="sum"
                              checked={values.hours_computation === "sum"}
                              onChange={handleChange}
                            />
                            <span>Sum of Check In and Out time.</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="shift_input_boxes"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* minimum hours half/full day */}
                  <div className="input-container mt-2 mb-2">
                    <div
                      className="shift_input_boxes"
                      style={{
                        display: "block",
                      }}
                    >
                      <div className="week_heading_shift mb-1">
                        <h1>{t("Minimum hour Computation")}</h1>
                      </div>
                      <div className="flex" style={{display:"flex",justifyContent:"space-between"}}>
                        {" "}
                        <div className="shiftmarginoption">
                          <div className="shiftlateby">
                            <p>
                              Half day minimum hours
                              <input
                                onInput={(e) => {
                                  e.target.value = (
                                    e.target.value || ""
                                  ).replace(/[^\d]/g, "");
                                }}
                                name="min_hours_halfday"
                                id="checklateby"
                                className="checklatebybox"
                                value={values.min_hours_halfday}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />{" "}
                            </p>
                          </div>
                          {errors.min_hours_halfday &&
                            touched.min_hours_halfday && (
                              <div
                                style={{ height: "20px", textAlign: "left" }}
                              >
                                <p className="form-error">
                                  {errors.min_hours_halfday}
                                </p>
                              </div>
                            )}
                        </div>
                        <div className="shiftmarginoption">
                          <div className="shiftlateby">
                            <p>
                              Full day minimum hours
                              <input
                                onInput={(e) => {
                                  e.target.value = (
                                    e.target.value || ""
                                  ).replace(/[^\d]/g, "");
                                }}
                                name="min_hours_fullday"
                                id="checklateby"
                                className="checklatebybox"
                                value={values.min_hours_fullday}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />{" "}
                            </p>
                          </div>
                          {errors.min_hours_fullday &&
                            touched.min_hours_fullday && (
                              <div
                                style={{ height: "20px", textAlign: "left" }}
                              >
                                <p className="form-error">
                                  {errors.min_hours_fullday}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="shift_input_boxes"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}

      {edit_shift && (
        <div className="modal">
          <div
            onClick={() => {
              setEdit_shift(!edit_shift);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Shift</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEdit_shift(!edit_shift);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              {/* {console.log("shift id is:",shiftId)} */}
              <Edit_shift
                shiftId={shiftId}
                setEdit_shift={setEdit_shift}
                get_all_shift={get_all_shift}
              />
            </div>
          </div>
        </div>
      )}

      {/* Add Employee  pop up*/}
      {addemployee && (
        <div className="modal">
          <div
            onClick={() => {
              setEmployee(!addemployee);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Employees</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEmployee(!addemployee);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              {/* {console.log("shift id is:",shiftId)} */}
              <AddEmployee
                shiftId={shiftId}
                shiftemployeeID={shiftemployeeID}
                setEmployee={setEmployee}
                get_all_shift={get_all_shift}
              />
            </div>
          </div>
        </div>
      )}
      <div className="addbtnbra">
        <button
          className="cusbtn"
          type="button"
          onClick={() => {
            setCreate_shift_modal(!Create_shift_modal);
          }}
        >
          Add Shift
        </button>
      </div>
      <div className="branchbox mt-2 mb-3">
        {shift ? (
          shift.map((ele, index) => {
            return (
              <div className="card Shiftcard" key={index}>
                <div className="shiftbox mb-2">
                  <div className="shiftleftarea">
                    <FaBusinessTime className="shiftbag" />
                  </div>
                  <div className="shiftrightarea">
                    <div className="shiftname">
                      <h1>{ele.shift_name}</h1>
                      <p>
                        {ele.start_time}-{ele.end_time}
                      </p>
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }} className="editbtndep">
                    <BiEdit
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShiftId(ele.id);
                        setEdit_shift(!edit_shift);
                      }}
                      color="var(--secondary-color)"
                    />
                  </div>
                </div>
                <div className="shiftbox mb-2">
                  <div className="shiftleftarea">
                    <FaUsers size="1.3em" />
                  </div>
                  <div className="shiftrightarea">
                    <div className="shiftuserslist">
                      {/* {ele.employee_data.slice(0, 4).map((ele, index) => {
                          return (
                            <div className="list" key={index}>
                              {ele.photo ? (
                                <img
                                  src={ele.photo}
                                />
                              ) : (
                                <FaUser/>
                              )}
                            </div>
                          );
                        })} */}
                      <IoMdAdd
                        onClick={(e) => {
                          setshiftemployeeID(ele.employee_ids);
                          setShiftId(ele.id);
                          setEmployee(true);
                        }}
                        style={{
                          cursor: "pointer",
                          background: "var(--primary-color)",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          color: "#ffffff",
                          marginLeft: "-10px",
                          border: "1px solid var(--secondary-color)",
                          objectFit: "cover",
                          padding: "4px",
                        }}
                      />{" "}
                      <div className="addbtnshift">
                        <p>{ele.employee_count} More </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shiftbox">
                  <div className="shiftleftarea">
                    <BiMenuAltRight size="1.3em" />
                  </div>
                  <div className="shiftrightarea">
                    <div className="shiftfulldetails">
                      <div className="workhourshift">
                        <p>Work Hours</p>
                        <p className="shourbox">{ele.shift_hours} </p>
                      </div>
                      <div className="workhourshift text-center">
                        <p>Week Off Days</p>
                        <p>{ele.off_days.join(" - ")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p
            style={{ marginTop: "25px", fontSize: "20px", fontWeight: "bold" }}
          >
            No Shift Found
          </p>
        )}
      </div>
    </>
  );
};

export default Shift;
