import React, { useState } from "react";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

const Time = () => {
  const [initialValues, setinitialValues] = useState({
    att: "",
    year: "",
  });
  //   const signUpSchema = Yup.object({
  //     att: Yup.number().required("Please enter a value"),
  //   });
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      console.log("hi", values);
      submit_att(values);
    },
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Generate an array of years from the current year to 10 years ago
  const years = Array.from({ length: 11 }, (_, i) => currentYear - i);

  const report = useSelector((state) => state.Reducer);

  //   att submit
  async function submit_att(e) {
    swal({
      title: "Downloading",
      text: "Please wait...",
      buttons: false,
      timerProgressBar: true,
      closeOnClickOutside: false,
    });
    try {
      await axiosInstance
        .get(
          `TimeInOutReport?month=${e.att}&year=${e.year}`,
          {
            responseType: "arraybuffer", // Ensure response is treated as binary data
          }
        )
        .then(async (response) => {
          console.log("update=", response);
          if (response.status == 200) {
            // Convert ArrayBuffer to Blob
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link element, set its href to the Blob URL, and trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "TimeSheet.xlsx"); // Set the desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            swal.close();
          } else {
            swal.close();
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      swal.close();
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  //   att submit
  return (

    <div
      className="toggleboxarea mb-2"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <h2>Download time sheet of
        <Tooltip title="Download the time sheet with the presence/absence of employees along with check-in and check-out times." placement="top">
          <IconButton>
            <FaInfoCircle size='0.5em' />
          </IconButton>
        </Tooltip>
      </h2>
      <form onSubmit={handleSubmit}>
        <div style={{ textAlign: "end" }}>
          <div style={{ display: "flex" }}>
            <div>
              <div style={{ width: "100%" }}>
                <select
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  name="att"
                  id="att"
                  value={values.att}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  {monthNames.map((month, index) => (
                    <option
                      key={index}
                      value={(index + 1).toString().padStart(2, "0")}
                    >
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              {/* {touched.att && errors.att && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.att}
                    </p>
                  )} */}
            </div>

            <div>
              <div style={{ width: "100%" }}>
                <select
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  name="year"
                  id="year"
                  value={values.year}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Year
                  </option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              {/* {touched.year && errors.year && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.year}
                    </p>
                  )} */}
            </div>
          </div>
          <button type="submit" className="cusbtn">
            Download
          </button>{" "}
        </div>
      </form>
    </div>

  );
};

const Leaves = () => {
  const [initialValues, setinitialValues] = useState({
    att: "",
    year: "",
  });
  //   const signUpSchema = Yup.object({
  //     att: Yup.number().required("Please enter a value"),
  //   });
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      console.log("hi", values);
      submit_att(values);
    },
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Generate an array of years from the current year to 10 years ago
  const years = Array.from({ length: 11 }, (_, i) => currentYear - i);

  const report = useSelector((state) => state.Reducer);

  //   att submit
  async function submit_att(e) {
    swal({
      title: "Downloading",
      text: "Please wait...",
      buttons: false,
      timerProgressBar: true,
      closeOnClickOutside: false,
    });
    try {
      await axiosInstance
        .get(
          `LeaveReport?month=${e.att}&year=${e.year}`,
          {
            responseType: "arraybuffer", // Ensure response is treated as binary data
          }
        )
        .then(async (response) => {
          console.log("update=", response);
          if (response.status == 200) {
            // Convert ArrayBuffer to Blob
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link element, set its href to the Blob URL, and trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Leave.xlsx"); // Set the desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            swal.close();
          } else {
            swal.close();
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      swal.close();
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  //   att submit
  return (
    <>
      <div
        className="toggleboxarea mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Leaves of
          <Tooltip title="Download the sheet to view the leave records of the employee." placement="top">
            <IconButton>
              <FaInfoCircle size='0.5em' />
            </IconButton>
          </Tooltip>
        </h2>
        <form onSubmit={handleSubmit}>
          <div style={{ textAlign: "end" }}>
            <div style={{ display: "flex" }}>
              <div>
                <div style={{ width: "100%" }}>
                  <select
                    style={{ marginRight: "3%" }}
                    className="custominputprofile"
                    name="att"
                    id="att"
                    value={values.att}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Month
                    </option>
                    {monthNames.map((month, index) => (
                      <option
                        key={index}
                        value={(index + 1).toString().padStart(2, "0")}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {touched.att && errors.att && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.att}
                    </p>
                  )} */}
              </div>

              <div>
                <div style={{ width: "100%" }}>
                  <select
                    style={{ marginRight: "3%" }}
                    className="custominputprofile"
                    name="year"
                    id="year"
                    value={values.year}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Year
                    </option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {touched.year && errors.year && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.year}
                    </p>
                  )} */}
              </div>
            </div>
            <button type="submit" className="cusbtn">
              Download
            </button>{" "}
          </div>
        </form>
      </div>
    </>
  );
};

const Reports = () => {
  const [initialValues, setinitialValues] = useState({
    att: "",
    year: "",
  });
  //   const signUpSchema = Yup.object({
  //     att: Yup.number().required("Please enter a value"),
  //   });
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      console.log("hi", values);
      submit_att(values);
    },
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Generate an array of years from the current year to 10 years ago
  const years = Array.from({ length: 11 }, (_, i) => currentYear - i);

  const report = useSelector((state) => state.Reducer);

  //   att submit
  async function submit_att(e) {
    swal({
      title: "Downloading",
      text: "Please wait...",
      buttons: false,
      timerProgressBar: true,
      closeOnClickOutside: false,
    });
    try {
      await axiosInstance
        .get(
          `AttendenceReport?month=${e.att}&year=${e.year}`,
          {
            responseType: "arraybuffer", // Ensure response is treated as binary data
          }
        )
        .then(async (response) => {
          console.log("update=", response);
          if (response.status == 200) {
            // Convert ArrayBuffer to Blob
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link element, set its href to the Blob URL, and trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "attendence.xlsx"); // Set the desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            swal.close();
          } else {
            swal.close();
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      swal.close();
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  //   att submit
  return (
    <>
      <div
        className="toggleboxarea mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Download attendence sheet of
          <Tooltip title="Download the attendance sheet with the presence/absence of employees` along with leaves taken." placement="top">
            <IconButton>
              <FaInfoCircle size='0.5em' />
            </IconButton>
          </Tooltip>
        </h2>
        <form onSubmit={handleSubmit}>
          <div style={{ textAlign: "end" }}>
            <div style={{ display: "flex" }}>
              <div>
                <div style={{ width: "100%" }}>
                  <select
                    style={{ marginRight: "3%" }}
                    className="custominputprofile"
                    name="att"
                    id="att"
                    value={values.att}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Month
                    </option>
                    {monthNames.map((month, index) => (
                      <option
                        key={index}
                        value={(index + 1).toString().padStart(2, "0")}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {touched.att && errors.att && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.att}
                  </p>
                )} */}
              </div>

              <div>
                <div style={{ width: "100%" }}>
                  <select
                    style={{ marginRight: "3%" }}
                    className="custominputprofile"
                    name="year"
                    id="year"
                    value={values.year}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Year
                    </option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {touched.year && errors.year && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.year}
                  </p>
                )} */}
              </div>
            </div>
            <button type="submit" className="cusbtn">
              Download
            </button>{" "}
          </div>
        </form>
      </div>

      <Time />

      <Leaves />
    </>
  );
};

export default Reports;
