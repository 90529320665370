import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "../../Navbar";
import {
  BsBackspaceFill,
  BsPersonFill,
  BsFillBagFill,
  BsFillPersonCheckFill,
  BsFillPersonDashFill,
  BsTriangle
} from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { IoMdLogIn } from "react-icons/io";
import { HiOutlineLogin } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Employee_personal from "./Employee_personal";
import Employee_work from "./Employee_work";
import Additional_details from "./Additional_details";
import Leave_details from "./Leave_details";
import Attendance_details from "./Attendance_details";
import { EMPLOYEDITOGGLESTATE } from "../../../redux/bulkbtns/Actions";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { FaUser, FaHome, FaInfoCircle } from "react-icons/fa";
import Document_deatils from "./Document_deatils";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import { IoMdPersonAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import Compressor from "compressorjs"
function Employee_edit() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [initialEmployeeData, setInitialEmployeeData] = useState("");
  const [activeData, setActiveData] = useState();
  const [activeValue, setActiveValue] = useState();
  const [genderForLeave, setGenderForLeave] = useState("");

  const get_employee_byid = useCallback(async () => {
    try {
      let requestData = {};
      let url = "";

      if (location.state && location.state.onboard) {
        url = `onboardById`;
        requestData.onboard_id = location.state.id;
      } else {
        url = `GetEmployeeById`;
        requestData.employee_id = location.state.id;
      }

      await axiosInstance
        .post(url, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          setActiveValue(response.data[0].active_status)
          setInitialEmployeeData(response.data);
          setPhoto(response.data[0].photo)
      
          
        });
    } catch (e) {

      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_employee_byid();
  }, [get_employee_byid]);

  //for tracking the active status
  useEffect(() => {

    setActiveValue(activeData)
  }, [activeData, initialEmployeeData]);

  // get employee by id

  // get cards by id
  const [cards_details, set_cards_details] = useState("");
  const get_cards_detail_byid = useCallback(async () => {
    try {
      let requestData = {};
      let url = "";

      if (location.state && location.state.onboard) {
        url = `EmployeeDetailCards`;
        requestData.onboard_id = location.state.id;
      } else {
        url = `EmployeeDetailCards`;
        requestData.employee_id = location.state.id;
      }

      await axiosInstance
        .post(url, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {

          set_cards_details(response.data.Data);
        });
    } catch (e) {
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_cards_detail_byid();
  }, [get_cards_detail_byid]);

  // get cards by id
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // only load current

  const [tabState, setTabState] = useState(() => {
    const storedValue = localStorage.getItem("employee_toggle");
    // Parse the JSON string back into an object
    const use = JSON.parse(storedValue);
    return use
      ? use
      : {
        showTab1: true,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      };
  });
  // console.log("tabState=", typeof tabState);
  // console.log("localStorage=", localStorage.getItem("employee_toggle"));
  const toggleTab = (index) => {
    if (index === 1) {
      setTabState({
        showTab1: true,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      });
      const set = {
        showTab1: true,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 2) {
      setTabState({
        showTab1: false,
        showTab2: true,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      });
      const set = {
        showTab1: false,
        showTab2: true,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 3) {
      setTabState({
        showTab1: false,
        showTab2: false,
        showTab3: true,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      });
      const set = {
        showTab1: false,
        showTab2: false,
        showTab3: true,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 4) {
      setTabState({
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: true,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      });
      const set = {
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: true,
        showTab5: false,
        showTab6: false,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 5) {
      setTabState({
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: true,
        showTab6: false,
        showTab7: false,
      });
      const set = {
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: true,
        showTab6: false,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 6) {
      setTabState({
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: true,
        showTab7: false,
      });
      const set = {
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: true,
        showTab7: false,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    } else if (index === 7) {
      setTabState({
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: true,
      });
      const set = {
        showTab1: true,
        showTab2: false,
        showTab3: false,
        showTab4: false,
        showTab5: false,
        showTab6: false,
        showTab7: true,
      };
      localStorage.setItem("employee_toggle", JSON.stringify(set));
      dispatch(EMPLOYEDITOGGLESTATE(index));
    }
  };

  // upload a photo
  const [photo, setPhoto] = useState(null);
  const fileInputRef = useRef(null);

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 800, 
        maxHeight: 800,
        success(compressedFile) {
          const photoURL = URL.createObjectURL(compressedFile);
          setPhoto(photoURL);
          upload_photo(compressedFile); 
        },
        error(err) {
          console.error("Compression error:", err);
        },
      });
    }
  };

  // api
  const upload_photo = useCallback(async (file) => {
    var dataa = new FormData();
    dataa.append("employee_id", location.state.id);
    dataa.append("photo", file);
    try {
      await axiosInstance.put("EditEmployee", dataa).then((response) => {

        if (response.status == 200) {
          const successMessage =
            response.data.message || "Something went wrong";
          swal({
            title: "Success!",
            text: successMessage,
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                className: "swal-button--custom",
              },
            },
          });

          // Close the SweetAlert after 3000 milliseconds (3 seconds)
          setTimeout(() => {
            swal.close();
          }, 2000);
        }
      });
    } catch (e) {
      console.log(e.response.data);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }, []);
  // api
  // upload a photo
  return (

    <div className="main-container">
      <div className="allcontent">
        <Navbar />
        <div className="mainbox">
          <div className="breadcrumbs_pop">
            <div></div>

            <button
              onClick={() => {
                if (location.state.onboard) {
                  navigate("/Onboard/Onboard");
                } else {
                  navigate("/Employees");
                }
              }}
              className="cusbtn"
            >
              <BsBackspaceFill size="1.2rem" />
            </button>
          </div>
          {/* <div className="card employee_edit mt-2">
              <div className="employee_name_desi"></div>

              <div className="employee_phone_email">
                <div className="phone_email">
                  <p className="padding_only">Phone:</p>
                  <p className="blue_color"></p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Email:</p>
                  <p className="blue_color"></p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Birthday:</p>
                  <p>11 June</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Gender:</p>
                  <p>Female</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Address:</p>
                  <p>1022-A type-2, Rail coach factory</p>
                </div>
              </div>
            </div> */}

          <div className="card employee_edit_new mt-2 ">
            <h4 className="employee_deta">Employee Details</h4>
            {/* <span className={` ${activeValue==="1"?"employee_active_deactive":"employee_deactive"}`}>
                <FaCircle size="0.6rem" /> {activeValue==="1"?"Active":"Not Active"}
              </span> */}
            <div className="employe_base_details">
              <div>
                {photo ? (
                  <div style={{ position: "relative" }}>
                    <img
                      src={photo}
                      alt="User"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        margin: "0px 5px 0px 5px",
                      }}
                    />
                    <div
                      className="edit_photo_upload"
                      onClick={handleEditClick}
                    >
                      <MdEdit style={{ color: "white" }} />
                    </div>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <FaUser
                      style={{
                        color: "var(--Whiteblack-color)",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        margin: "0px 5px 0px 5px",
                      }}
                    />
                    <div
                      className="edit_photo_upload"
                      onClick={handleEditClick}
                    >
                      <MdEdit style={{ color: "white" }} />
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <div style={{ flex: "1" }}>
                <div className="name_designation_empl">
                  <h1>
                    {initialEmployeeData
                      ? `${initialEmployeeData[0].first_name} ${initialEmployeeData[0].last_name}`
                      : ""}
                  </h1>

                  <div className="employe_baseaddtional">
                    <div>
                      <span>Role</span>
                      <p>  {initialEmployeeData
                        ? initialEmployeeData[0].designation
                        : ""}</p>
                    </div>
                    <div>
                      <span>Employee Id</span>
                      <p>
                        {initialEmployeeData
                          ? initialEmployeeData[0].employee_id
                          : ""}
                      </p>
                    </div>
                    <div>
                      <span>Phone</span>
                      <p>
                        {initialEmployeeData
                          ? initialEmployeeData[0].work_phone
                          : ""}
                      </p>
                    </div>
                    <div>
                      <span>Email</span>
                      <p>
                        {initialEmployeeData
                          ? initialEmployeeData[0].work_email
                          : ""}
                      </p>
                    </div>
                  </div>
                  {/* <h1>{t('greeting')} </h1> */}
                  {/* <h4>
                    
                  </h4> */}
                  {/* <p>
                    {initialEmployeeData
                      ? initialEmployeeData[0].employee_id
                      : ""}
                  </p> */}
                </div>
              </div>
            </div>
            <div className="employee_edit_attandancebox">
              <div className="emp_edit_attandCrad">
                <div className="emp_edit_attandCrad_icon">
                  <MdDateRange className="iconsstyle" />
                </div>
                <div className="emp_edit_attandCrad_Para">
                  <p>Total Attendance</p>
                  <h2>
                    {cards_details ? cards_details.total_attendence : ""}
                  </h2>
                </div>
              </div>
              <div className="emp_edit_attandCrad">
                <div className="emp_edit_attandCrad_icon">
                  <IoMdLogIn className="iconsstyle" />
                </div>
                <div className="emp_edit_attandCrad_Para">
                  <p>Avg Check in Time
                    <Tooltip title="The average time employees check in during a month." placement="top">
                      <IconButton>
                        <FaInfoCircle size='0.5em' />
                      </IconButton>
                    </Tooltip>
                  </p>
                  <h2>{cards_details ? cards_details.avg_checkin : ""}</h2>
                </div>
              </div>
              <div className="emp_edit_attandCrad">
                <div className="emp_edit_attandCrad_icon">
                  <HiOutlineLogin className="iconsstyle" />
                </div>
                <div className="emp_edit_attandCrad_Para">
                  <p>Avg Check out Time
                    <Tooltip title="The average time employees check out during a month." placement="top">
                      <IconButton>
                        <FaInfoCircle size='0.5em' />
                      </IconButton>
                    </Tooltip>
                  </p>
                  <h2>{cards_details ? cards_details.avg_checkout : ""}</h2>
                </div>
              </div>
              <div className="emp_edit_attandCrad">
                <div className="emp_edit_attandCrad_icon">
                  <FaHome className="iconsstyle" />
                </div>
                <div className="emp_edit_attandCrad_Para">
                  <p>Total Leaves</p>
                  <h2>{cards_details ? cards_details.leave_count : ""}</h2>
                </div>
              </div>
            </div>

            <div className="active_inactive">
              <h1></h1>
            </div>
          </div>

          {/* details */}
          <div className="tabs_deatils">
            {location.state.onboard ? (
              <div className="bloc-tabs card mt-3">
                <p style={{ color: "var(--secondary-color) !important" }}>
                  Employee Settings
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 1
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <BsPersonFill /> Employee Personal Details
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 2
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(2)}
                >
                  <BsTriangle />
                  Additional Details
                </p>
                {/* <p
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 3
                        ? "tabs active-tabs"
                        : "tabs"
                    }
                    onClick={() => toggleTab(3)}
                  >
                    <BsTriangle />
                    Document Details
                  </p> */}
                {/* <p
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 7
                        ? "tabs active-tabs"
                        : "tabs"
                    }
                    onClick={() => toggleTab(7)}
                  >
                    <BsTriangle />
                    Assets Details
                  </p> */}
              </div>
            ) : (
              <div className="bloc-tabs card mt-3">
                <p style={{ color: "var(--secondary-color) !important" }}>
                  Employee Settings
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 1
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <BsPersonFill /> Employee Personal Details
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 2
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(2)}
                >
                  <BsFillBagFill />
                  Employee Work Details
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 3
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(3)}
                >
                  <IoMdPersonAdd /> Additional Details
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 4
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(4)}
                >
                  <BsFillPersonCheckFill /> Attendance Details
                </p>
                <p
                  className={
                    employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 5
                      ? "tabs active-tabs"
                      : "tabs"
                  }
                  onClick={() => toggleTab(5)}
                >
                  <BsFillPersonDashFill /> Leave Details
                </p>
                {/* <p
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 6
                        ? "tabs active-tabs"
                        : "tabs"
                    }
                    onClick={() => toggleTab(6)}
                  >
                    <BsFillPersonDashFill /> Document Details
                  </p> */}
                {/* <p
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 7
                        ? "tabs active-tabs"
                        : "tabs"
                    }
                    onClick={() => toggleTab(7)}
                  >
                    <BsFillPersonDashFill /> Assets Details
                  </p> */}
              </div>
            )}

            {location.state.onboard ? (
              <div className="content-tabs mt-3 card">
                {tabState.showTab1 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 1
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Employee_personal
                      id={location.state.id}
                      first_name={location.state.first_name}
                      last_name={location.state.last_name}
                      onboard={location.state.onboard}
                    />
                  </div>
                )}
                {tabState.showTab2 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 2
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Additional_details
                      id={location.state.id}
                      onboard={location.state.onboard}
                    />
                  </div>
                )}
                {tabState.showTab3 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 3
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Document_deatils
                      id={location.state.id}
                      onboard={location.state.onboard}
                    />
                  </div>
                )}
                {/* {tabState.showTab7 && (
                    <div
                      className={
                        employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 7
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <Assets_detail
                        id={location.state.id}
                        onboard={location.state.onboard}
                      />
                    </div>
                  )} */}
              </div>
            ) : (
              <div className="content-tabs mt-3 card">
                {tabState.showTab1 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 1
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Employee_personal
                      genderForLeave={genderForLeave}
                      setGenderForLeave={setGenderForLeave}
                      id={location.state.id}
                      first_name={location.state.first_name}
                      last_name={location.state.last_name}
                      employee_id={location.state.employee_id}
                    />
                  </div>
                )}
                {tabState.showTab2 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 2
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Employee_work
                      id={location.state.id}
                      employee_id={location.state.employee_id}
                      activeData={activeData}
                      setActiveData={setActiveData}
                    />
                  </div>
                )}
                {tabState.showTab3 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 3
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Additional_details
                      id={location.state.id}
                      employee_id={location.state.employee_id}
                    />
                  </div>
                )}
                {tabState.showTab4 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 4
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Attendance_details
                      id={location.state.id}
                      employee_id={location.state.employee_id}
                    />
                  </div>
                )}
                {tabState.showTab5 && (
                  <div
                    className={
                      employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 5
                        ? "content  active-content"
                        : "content"
                    }
                  >
                    <Leave_details
                      genderForLeave={genderForLeave}
                      setGenderForLeave={setGenderForLeave}
                      id={location.state.id}
                      employee_id={location.state.employee_id}
                    />
                  </div>
                )}
                {/* {tabState.showTab6 && (
                    <div
                      className={
                        employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 6
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <Document_deatils
                        id={location.state.id}
                        employee_id={location.state.employee_id}
                      />
                    </div>
                  )} */}
                {/* {tabState.showTab7 && (
                    <div
                      className={
                        employee_page.EMPLOYEE_EDIT_TOGGLE_STATE === 7
                          ? "content  active-content"
                          : "content"
                      }
                    >
                      <Assets_detail
                        id={location.state.id}
                        employee_id={location.state.employee_id}
                      />
                    </div>
                  )} */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
}

export default Employee_edit;
