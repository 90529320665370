import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import { MdLocationOn } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
// single branch
const SingleBranch = (props) => {
  // for Translation
  const { t } = useTranslation();
  // Create Event
  const [Create_branch_modal, setCreate_branch_modal] = useState(false);
  const branch_redux = useSelector((state) => state.Reducer);
  const [loading, setLoading] = useState(false);
  // Add branch popup start
  const signUpSchema = Yup.object({
    branchname: Yup.string().required("Enter Branch Name"),
    branchphone: Yup.string().required("Enter Branch Phone"),
    branchcity: Yup.string().required("Enter Branch City"),
    branchlatitude: Yup.string().required("Enter Branch Latitude"),
    branchlongitude: Yup.string().required("Enter Branch Longitude"),
    geo_fencing: Yup.boolean().required("Select Yes or No"),
    geo_fencing_meter: Yup.number("Only numbers are allowed")
      .positive("Enter positive number")
      .integer()
      .test(
        "conditionalRequired",
        "Geofencing meter is required",
        function (value) {
          const { geo_fencing } = this.parent; // Access other fields in the form

          if (geo_fencing) {
            return !!value; // Return true if checkinby is required and has a value
          }

          return true; // Return true for non-required case
        }
      ),
    auto_checkin_out: Yup.boolean().required("Select Yes or No"),
    auto_checkin_out_thershold: Yup.number("Only numbers are allowed")
      .positive("Enter positive number")
      .integer()
      .test("conditionalRequired", "Thershold is required", function (value) {
        const { auto_checkin_out } = this.parent;

        if (auto_checkin_out) {
          return !!value;
        }

        return true;
      }),
  });

  const [initialValues, setinitialValues] = useState({
    branchname: "",
    branchphone: "",
    branchcity: "",
    branchlatitude: "",
    branchlongitude: "",
    addressline1: "",
    addressline2: "",
    geo_fencing: "0",
    geo_fencing_meter: 0,
    auto_checkin_out: "0",
    auto_checkin_out_thershold: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      Edit_branch(values);
    },
  });

  // get single branch
  useEffect(() => {
    get_single_branch();
  }, []);
  async function get_single_branch() {
    try {
      await axiosInstance
        .post(
          `BranchById`,
          {
            company_id: branch_redux.COMPANYAPI,
            branch_id: props.branch_id,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("single=", response);
          setinitialValues({
            branchname: response.data[0].name,
            branchphone: response.data[0].phone,
            branchcity: response.data[0].city,
            branchlatitude: response.data[0].latitude,
            branchlongitude: response.data[0].longitude,
            addressline1: response.data[0].addressline1,
            addressline2: response.data[0].addressline2,
            geo_fencing: response.data[0].geo_fencing,
            geo_fencing_meter: response.data[0].geo_fencing_meter,
            auto_checkin_out: response.data[0].auto_checkin_out,
            auto_checkin_out_thershold:response.data[0].auto_checkin_out_threshold
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single branch

  // add branch deatils
  async function Edit_branch(e) {
    setLoading(true);
    try {
      const geofencingYesorNo = String(e.geo_fencing);
      const autoCheckInCheckOut = String(e.auto_checkin_out);
      await axiosInstance
        .put(
          `EditBranch`,
          {
            name: e.branchname,
            phone: e.branchphone,
            city: e.branchcity,
            branch_latitude: e.branchlatitude,
            branch_longitude: e.branchlongitude,
            addressline1: e.addressline1,
            addressline2: e.addressline2,
            company_id: branch_redux.COMPANYAPI,
            branch_id: props.branch_id,
            geo_fencing: geofencingYesorNo,
            geo_fencing_meter: e.geo_fencing_meter,
            auto_checkin_out: autoCheckInCheckOut,
            auto_checkin_out_threshold: e.auto_checkin_out_thershold,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            props.setCreate_single_branch(false);
            props.get_all_branch();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setCreate_branch_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      setCreate_branch_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    } finally {
      setLoading(false);
    }
  }

  // add branch deatils
  const handleClick_allow = () => {
    values.geo_fencing === 1
      ? setFieldValue("geo_fencing", 0)
      : setFieldValue("geo_fencing", 1);
  };
  const handleClick_allowautocheckInout = (event) => {
    values.auto_checkin_out === 1
      ? setFieldValue("auto_checkin_out", 0)
      : setFieldValue("auto_checkin_out", 1);
  };
  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          name="branchname"
          value={values.branchname}
          onInput={(e) => {
            e.target.value = (e.target.value || "").replace(/[^A-Za-z\s]/g, "");
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Branch Name*"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.branchname && touched.branchname ? (
            <p className="form-error">{errors.branchname}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <TextField
          name="branchphone"
          value={values.branchphone}
          onInput={(e) => {
            const input = e.target.value || "";
            e.target.value = input.replace(/[^\d]/g, "").slice(0, 10);
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Branch Phone*"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.branchphone && touched.branchphone ? (
            <p className="form-error">{errors.branchphone}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <TextField
          name="branchcity"
          value={values.branchcity}
          onInput={(e) => {
            e.target.value = (e.target.value || "").replace(/[^A-Za-z\s]/g, "");
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Branch City*"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.branchcity && touched.branchcity ? (
            <p className="form-error">{errors.branchcity}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <TextField
          name="branchlatitude"
          value={values.branchlatitude}
          onInput={(e) => {
            e.target.value = (e.target.value || "").replace(/[^0-9.]/g, "");
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Branch Latitude"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.branchlatitude && touched.branchlatitude ? (
            <p className="form-error">{errors.branchlatitude}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <TextField
          name="branchlongitude"
          value={values.branchlongitude}
          onInput={(e) => {
            e.target.value = (e.target.value || "").replace(/[^0-9.]/g, "");
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Branch Longitude"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.branchlongitude && touched.branchlongitude ? (
            <p className="form-error">{errors.branchlongitude}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          name="addressline1"
          value={values.addressline1}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Address Line 1"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.addressline1 && touched.addressline1 ? (
            <p className="form-error">{errors.addressline1}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          name="addressline2"
          value={values.addressline2}
          onChange={handleChange}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Address Line 2"
          variant="outlined"
        />

        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.addressline2 && touched.addressline2 ? (
            <p className="form-error">{errors.addressline2}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2 mb-2">
        <div
          className="shift_input_boxes"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="week_heading_shift mb-1">
            <h1>{t("Geofencing allowed")}</h1>
          </div>
          <div className="shift_input_boxes">
            <div className="radio_btn" style={{ marginLeft: "0" }}>
              <label>
                <input
                  type="radio"
                  name="geo_fencing"
                  value={1}
                  checked={values.geo_fencing === 1}
                  onChange={handleClick_allow}
                />
                <span>Yes</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  name="geo_fencing"
                  value={0}
                  checked={values.geo_fencing === 0}
                  onChange={handleClick_allow}
                />
                <span>No</span>
              </label>
            </div>
          </div>
        </div>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.geo_fencing && touched.geo_fencing ? (
            <p className="form-error">{errors.geo_fencing}</p>
          ) : null}
        </div>

        {values.geo_fencing === 1 && (
          <div className="shiftmarginoption">
            <div className="shiftlateby">
              <p>
                Geofencing Range In Meter
                <input
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                  name="geo_fencing_meter"
                  id="checklateby"
                  className="checklatebybox"
                  value={values.geo_fencing_meter}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
              </p>
            </div>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.geo_fencing_meter && touched.geo_fencing_meter ? (
                <p className="form-error">{errors.geo_fencing_meter}</p>
              ) : null}
            </div>
          </div>
        )}

        <div
          className="shift_input_boxes"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="week_heading_shift mb-1">
            <h1>{t("Auto checkIn checkOut allowed")}</h1>
          </div>
          <div className="shift_input_boxes">
            <div className="radio_btn" style={{ marginLeft: "0" }}>
              <label>
                <input
                  type="radio"
                  name="auto_checkin_out"
                  value={1}
                  checked={values.auto_checkin_out === 1}
                  onChange={handleClick_allowautocheckInout}
                />
                <span>Yes</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  name="auto_checkin_out"
                  value={0}
                  checked={values.auto_checkin_out === 0}
                  onChange={handleClick_allowautocheckInout}
                />
                <span>No</span>
              </label>
            </div>
          </div>
        </div>
        {values.auto_checkin_out === 1 && (
          <div className="shiftmarginoption">
            <div className="shiftlateby">
              <p>
                Auto checkin checkout thershold
                <input
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                  name="auto_checkin_out_thershold"
                  id="checklateby"
                  className="checklatebybox"
                  value={values.auto_checkin_out_thershold}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
              </p>
            </div>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.auto_checkin_out_thershold &&
              touched.auto_checkin_out_thershold ? (
                <p className="form-error">
                  {errors.auto_checkin_out_thershold}
                </p>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <div className="text-center mb-3">
        <button type="submit" className="cusbtn" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};
// single branch
const Branch = (props) => {
  // for Translation
  const { t } = useTranslation();
  // Create Event
  const [Create_branch_modal, setCreate_branch_modal] = useState(false);
  const [Create_single_branch, setCreate_single_branch] = useState(false);
  const [brnach_id, setBrnach_id] = useState("");
  const branch_redux = useSelector((state) => state.Reducer);
  const [loading, setLoading] = useState(false);
  // Add branch popup start
  const signUpSchema = Yup.object({
    branchname: Yup.string().required("Enter Branch Name"),
    branchphone: Yup.string().required("Enter Branch Phone"),
    branchcity: Yup.string().required("Enter Branch City"),
    branchlatitude: Yup.string().required("Enter Branch Latitude"),
    branchlongitude: Yup.string().required("Enter Branch Longitude"),
    geo_fencing: Yup.boolean().required("Select Yes or No"),
    geo_fencing_meter: Yup.number("Only numbers are allowed")
      .positive("Enter positive number")
      .integer()
      .test(
        "conditionalRequired",
        "Geofencing meter is required",
        function (value) {
          const { geo_fencing } = this.parent; // Access other fields in the form

          if (geo_fencing) {
            return !!value; // Return true if checkinby is required and has a value
          }

          return true; // Return true for non-required case
        }
      ),
    auto_checkin_out: Yup.boolean().required("Select Yes or No"),
    auto_checkin_out_thershold: Yup.number("Only numbers are allowed")
      .positive("Enter positive number")
      .integer()
      .test("conditionalRequired", "Thershold is required", function (value) {
        const { auto_checkin_out } = this.parent;

        if (auto_checkin_out) {
          return !!value;
        }

        return true;
      }),
  });

  const [initialValues, setinitialValues] = useState({
    branchname: "",
    branchphone: "",
    branchcity: "",
    branchlatitude: "",
    branchlongitude: "",
    addressline1: "",
    addressline2: "",
    geo_fencing: "0",
    geo_fencing_meter: "",
    auto_checkin_out: "0",
    auto_checkin_out_thershold: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      Edit_branch(values);
    },
  });

  // get all branch
  const [branch_data, setbranch_data] = useState("");
  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      get_all_branch();
    }
  }, [props.tabStyle]);

  // get all branch
  async function get_all_branch() {
    try {
      await axiosInstance
        .get(`AllBranches`, {
          headers: {
            Authorization: `Bearer ${branch_redux.TOKEN}`,
          },
          httpsAgent: branch_redux.HTTP_AGENT,
        })
        .then((response) => {
          setbranch_data(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  // add branch deatils
  async function Edit_branch(e) {
    const geofencingYesorNo = String(e.geo_fencing);
    const Geofencingvalue = Number(e.geo_fencing_meter);
    const autoCheckInCheckOut = String(e.auto_checkin_out);
    setLoading(true);
    try {
      await axiosInstance
        .post(
          `InsertBranch`,
          {
            branch_name: e.branchname,
            branch_phone: e.branchphone,
            branch_city: e.branchcity,
            branch_latitude: e.branchlatitude,
            branch_longitude: e.branchlongitude,
            addressline1: e.addressline1,
            addressline2: e.addressline2,
            company_id: branch_redux.COMPANYAPI,
            geo_fencing: geofencingYesorNo,
            geo_fencing_meter: Geofencingvalue,
            auto_checkin_out: autoCheckInCheckOut,
            auto_checkin_out_threshold: e.auto_checkin_out_thershold,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            get_all_branch();
            setCreate_branch_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setCreate_branch_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      setCreate_branch_modal(false);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    } finally {
      setLoading(false);
    }
  }

  const handleClick_allow = () => {
    values.geo_fencing === "1"
      ? setFieldValue("geo_fencing", "0")
      : setFieldValue("geo_fencing", "1");
  };
  const handleClick_allowautocheckInout = (event) => {
    const newValue = event.target.value;
    setFieldValue("auto_checkin_out", newValue);
  };

  return (
    <>
      {/* pop up */}
      {Create_branch_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_branch_modal(!Create_branch_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Branch</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_branch_modal(!Create_branch_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="branchname"
                    value={values.branchname}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Name*"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchname && touched.branchname ? (
                      <p className="form-error">{errors.branchname}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchphone"
                    value={values.branchphone}
                    onInput={(e) => {
                      let cleanedValue = (e.target.value || "").replace(
                        /[^\d]/g,
                        ""
                      );
                      cleanedValue = cleanedValue.slice(0, 10);
                      e.target.value = cleanedValue;
                      handleChange(e);
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Phone*"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchphone && touched.branchphone ? (
                      <p className="form-error">{errors.branchphone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchcity"
                    value={values.branchcity}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch City*"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchcity && touched.branchcity ? (
                      <p className="form-error">{errors.branchcity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchlatitude"
                    value={values.branchlatitude}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^0-9.]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Latitude*"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchlatitude && touched.branchlatitude ? (
                      <p className="form-error">{errors.branchlatitude}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchlongitude"
                    value={values.branchlongitude}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^0-9.]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Longitude*"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchlongitude && touched.branchlongitude ? (
                      <p className="form-error">{errors.branchlongitude}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="addressline1"
                    value={values.addressline1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Address Line 1"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.addressline1 && touched.addressline1 ? (
                      <p className="form-error">{errors.addressline1}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="addressline2"
                    value={values.addressline2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Address Line 2"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.addressline2 && touched.addressline2 ? (
                      <p className="form-error">{errors.addressline2}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2 mb-2">
                  <div
                    className="shift_input_boxes"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="week_heading_shift mb-1">
                      <h1>{t("Geofencing allowed")}</h1>
                    </div>
                    <div className="shift_input_boxes">
                      <div className="radio_btn" style={{ marginLeft: "0" }}>
                        <label>
                          <input
                            type="radio"
                            name="geo_fencing"
                            value="1"
                            checked={values.geo_fencing === "1"}
                            onChange={handleClick_allow}
                          />
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="radio_btn">
                        <label>
                          <input
                            type="radio"
                            name="geo_fencing"
                            value="0"
                            checked={values.geo_fencing === "0"}
                            onChange={handleClick_allow}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values.geo_fencing === "1" && (
                    <div className="shiftmarginoption">
                      <div className="shiftlateby">
                        <p>
                          Geofencing Range In Meter
                          <input
                            onInput={(e) => {
                              e.target.value = (e.target.value || "").replace(
                                /[^\d]/g,
                                ""
                              );
                            }}
                            name="geo_fencing_meter"
                            id="checklateby"
                            className="checklatebybox"
                            value={values.geo_fencing_meter}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                        </p>
                      </div>
                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.geo_fencing_meter &&
                        touched.geo_fencing_meter ? (
                          <p className="form-error">
                            {errors.geo_fencing_meter}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div
                    className="shift_input_boxes"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="week_heading_shift mb-1">
                      <h1>{t("Auto checkIn checkOut allowed")}</h1>
                    </div>
                    <div className="shift_input_boxes">
                      <div className="radio_btn" style={{ marginLeft: "0" }}>
                        <label>
                          <input
                            type="radio"
                            name="auto_checkin_out"
                            value="1"
                            checked={values.auto_checkin_out === "1"}
                            onChange={handleClick_allowautocheckInout}
                          />
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="radio_btn">
                        <label>
                          <input
                            type="radio"
                            name="auto_checkin_out"
                            value="0"
                            checked={values.auto_checkin_out === "0"}
                            onChange={handleClick_allowautocheckInout}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values.auto_checkin_out == 1 && (
                    <div className="shiftmarginoption">
                      <div className="shiftlateby">
                        <p>
                          Auto checkin checkout thershold
                          <input
                            onInput={(e) => {
                              e.target.value = (e.target.value || "").replace(
                                /[^\d]/g,
                                ""
                              );
                            }}
                            name="auto_checkin_out_thershold"
                            id="checklateby"
                            className="checklatebybox"
                            value={values.auto_checkin_out_thershold}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                        </p>
                      </div>
                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.auto_checkin_out_thershold &&
                        touched.auto_checkin_out_thershold ? (
                          <p className="form-error">
                            {errors.auto_checkin_out_thershold}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>

                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}

      {/* single branch  */}
      {/* pop up */}
      {Create_single_branch && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_single_branch(!Create_single_branch);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Branch</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_single_branch(!Create_single_branch);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <SingleBranch
                branch_id={brnach_id}
                setCreate_single_branch={setCreate_single_branch}
                get_all_branch={get_all_branch}
              />
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}
      {/* single branch  */}

      <div className="addbtnbra">
        <button
          className="cusbtn"
          type="button"
          onClick={() => {
            setCreate_branch_modal(!Create_branch_modal);
          }}
        >
          Add Branch
        </button>
      </div>
      <div className="branchbox mt-5 mb-3">
        {branch_data ? (
          branch_data.map((ele, index) => {
            return (
              <div className="card brachcard mb-3" key={index}>
                <div className="branchiconbox">
                  <MdLocationOn size="1.5em" />
                </div>
                <div style={{ textAlign: "right" }} className="editbtndep">
                  <BiEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setBrnach_id(ele.id);
                      setCreate_single_branch(!Create_single_branch);
                    }}
                    color="var(--secondary-color)"
                  />
                </div>
                <h1>{ele.name}</h1>
                <p>{ele.phone}</p>
                <p>{ele.addressline1}</p>
                {/* <p>Rally Infra H-157, Sector 62, Noida</p> */}
              </div>
            );
          })
        ) : (
          <p
            style={{ marginTop: "25px", fontSize: "20px", fontWeight: "bold" }}
          >
            No Branch Found
          </p>
        )}
      </div>
    </>
  );
};

export default Branch;
