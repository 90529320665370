import React, { useEffect } from "react";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

const Leave_Settings = (props) => {
  const signUpSchema = Yup.object({
    unplannedLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .min(0, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 99"),
    maternityLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .min(0, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 99"),
    maxLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please Enter A positive Number")
      .integer("Please Enter a Whole Number")
      .min(1, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 99"),
    resetLeavesMonth: Yup.string(),
    leave_type: Yup.string(),
    compofff: Yup.string().required("Select Yes or No"),

    hours: Yup.string().test(
      "conditionalRequired",
      "Enter value",
      function (value) {
        const { compofff } = this.parent;
        if (compofff === "1") {
          return !!value;
        }
        return true;
      }
    ),
    exp_time: Yup.string().test(
      "conditionalRequired",
      "Enter value",
      function (value) {
        const { compofff } = this.parent;
        if (compofff === "1") {
          return !!value;
        }
        return true;
      }
    ),
    leavedistributed: Yup.string().required("Please choose a value"),
  });

  const [checkFeilds, setChecksFeilds] = useState("");
  const [leavesettingID, setleavesettingID] = useState();
  const leavesetting = useSelector((state) => state.Reducer);
  const [loading, setLoading] = useState(false);
  const [initialValues, setinitialValues] = useState({
    unplannedLeaves: "",
    numberOfWFhallowed: 0,
    // monthName: "",
    maternityLeaves: "",
    maxLeaves: "",
    leaveWOP: "",
    // WFHAllowed: "",
    clubbing_leaves: "",
    leave_type: "",
    formatOfCreditLeaves: "number",
    max_ClubbedLeaves: "",
    resetleavesDay: "",
    resetLeavesMonth: "",
    resetLeave: "",
    compofff: "",
    leavecredit: "",
    leavedistributed: "",
    hours: "",
    exp_time: "",
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      if (checkFeilds === "post") {
        post_leave_setting(values);
      } else if (checkFeilds === "put") {
        put_leave_setting(values);
      }
    },
  });

  //POST REQUEST
  async function post_leave_setting(e) {
    const resetLeavevalue = values.resetLeave;
    try {
      await axiosInstance
        .post(
          `InsertLeave`,
          {
            // leave_w_pay: e.leaveWOP,
            // leave_w_pay: "0",
            unplan_leaves: e.unplannedLeaves,
            // wfh: e.WFHAllowed,
            // wfh: "0",
            // no_wfh_month: e.numberOfWFhallowed,
            // wfh_After_join: e.monthName,
            //   no_wfh_month: 0,
            // wfh_After_join:0,
            // maternity_criteria: e.maternityLeaves,
            //  maternity_criteria: "1",
            max_leaves: e.maxLeaves,
            // reset_leaves: "1",
            leave_distribution: e.leavedistributed,
            // date_distribution: e.leavecredit,
            // date_distribution: 1,
            // clubbing_leaves: e.clubbing_leaves,
            // clubbing_leaves:"0",
            // leave_type: e.leave_type,
            // formatOfCreditLeaves: "number",
            // max_ClubbedLeaves: e.max_ClubbedLeaves,
            comp_off: e.compofff,
            minimum_comp_hour: e.hours,
            expirationTime_compoff: e.exp_time,
            reset_leaves: `30/${resetLeavevalue}`,
          },
          {
            headers: {
              Authorization: `Bearer ${leavesetting.TOKEN}`,
            },
            httpsAgent: leavesetting.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  //GET REQUEST leave setting

  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      leave_setting_get();
    }
  }, [props.tabStyle]);
  async function leave_setting_get() {
    try {
      setLoading(true);
      await axiosInstance
        .get(`LeaveSettings`, {
          headers: {
            Authorization: `Bearer ${leavesetting.TOKEN}`,
          },
          httpsAgent: leavesetting.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200 && response.data[0]) {
            setleavesettingID(response.data[0].id);
            setChecksFeilds("put");
            const resetLeavesData = response.data[0].reset_leaves;
            const parts = resetLeavesData.split("/");

            // parts[0] contains the data before the slash
            const rday = parts[0].trim();

            // parts[1] contains the data after the slash
            const rmonth = parts[1].trim();
            const resetleavevalue = response.data[0].reset_leaves;
            const trimmedresetLeave = resetleavevalue.split("/")[1];
            setinitialValues({
              unplannedLeaves: response.data[0].unplan_leaves,
              maternityLeaves: response.data[0].maternity_criteria,
              maxLeaves: response.data[0].max_leaves,
              resetleavesDay: rday,
              resetLeavesMonth: rmonth,
              compofff: response.data[0].comp_off,
              hours: response.data[0].min_comp_hour,
              exp_time: response.data[0].expirationTime_compoff,
              resetLeave: trimmedresetLeave,
              leavedistributed: response.data[0].leave_distribution,
            });
          } else if (response.data.message) {
            setChecksFeilds("post");
          }
        });
    } catch (e) {
      console.log(e.response.data.error);
    } finally {
      setLoading(false);
    }
  }

  //PUT REQUEST leave setting

  async function put_leave_setting(e) {
    const resetLeavevalue = values.resetLeave;
    try {
      setLoading(true);
      await axiosInstance
        .put(
          `EditLeaveSetting`,
          {
            // leave_w_pay: e.leaveWOP,
            leave_w_pay: "0",
            unplan_leaves: e.unplannedLeaves,
            // wfh: e.WFHAllowed,
            // wfh:"0",
            // no_wfh_month: e.numberOfWFhallowed,
            // wfh_After_join: e.monthName,
            // no_wfh_month: 0,
            // wfh_After_join:0,
            maternity_criteria: e.maternityLeaves,
            max_leaves: e.maxLeaves,
            // reset_leaves: e.resetLeave,
            leave_distribution: e.leavedistributed,
            // date_distribution: no,
            date_distribution: 1,
            comp_off: e.compofff,
            // clubbing_leaves: e.clubbing_leaves,
            clubbing_leaves: "0",
            // leave_type: e.leave_type,
            formatOfCreditLeaves: "number",
            // max_ClubbedLeaves: e.max_ClubbedLeaves,
            minimum_comp_hour: e.hours,
            expirationTime_compoff: e.exp_time,
            leaveSetttings_id: leavesettingID,
            reset_leaves: `30/${resetLeavevalue}`,
          },
          {
            headers: {
              Authorization: `Bearer ${leavesetting.TOKEN}`,
            },
            httpsAgent: leavesetting.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    } finally {
      setLoading(false);
    }
  }
  const [CasualleaveValue, setCasualleaveValue] = useState();
  const [EarnedleaveValue, setEarnedleaveValue] = useState();

  //api for getting id of leaves (like casual, earned)
  async function get_leave_type_id(leave_name) {
    try {
      const response = await axiosInstance.get(`AllLeaveType`, {
        headers: {
          Authorization: `Bearer ${leavesetting.TOKEN}`,
        },
        httpsAgent: leavesetting.HTTP_AGENT,
      });

      const leavetype = response.data.find(
        (leave) => leave.type === leave_name
      );

      if (leave_name === "Casual") {
        setCasualleaveValue(leavetype.id);
      } else if (leave_name === "Earned") {
        setEarnedleaveValue(leavetype.id);
      }
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      get_leave_type_id("Earned");
      get_leave_type_id("Casual");
    }
  }, [props.tabStyle]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="toggleboxcontent">
        {/* <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>If leave without pay is allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="1"
                    checked={values.leaveWOP === "1"}
                    onChange={(e) => {
                      setFieldValue("leaveWOP", e.target.value);
                    }}
                  />
                </label>
                <span>Yes</span>
              </div>
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="0"
                    checked={values.leaveWOP === "0"}
                    onChange={(e) => {
                      setFieldValue("leaveWOP", e.target.value);
                    }}
                  />
                </label>

                <span>No</span>
              </div>
            </div>
          </div>
          {touched.leaveWOP && errors.leaveWOP && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.leaveWOP}
            </p>
          )}
        </div> */}

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>The number of unplanned leaves allowed is</h2>
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  autoComplete="off"
                  name="unplannedLeaves"
                  id="unplannedLeaves"
                  // placeholder="INV"
                  value={values.unplannedLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />{" "}
                <h2>Days</h2>
                <span></span>
              </div>
            </div>
          </div>
          {touched.unplannedLeaves && errors.unplannedLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.unplannedLeaves}
            </p>
          )}
        </div>

        {/* <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Is WFH allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) =>
                      setFieldValue("WFHAllowed", e.target.value)
                    }
                    checked={values.WFHAllowed === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("WFHAllowed", e.target.value);
                    }}
                    checked={values.WFHAllowed === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.WFHAllowed && errors.WFHAllowed && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}
        </div> */}
        {/* {values.WFHAllowed == "1" && (
          <>
            <div
              className="toggleboxarea mb-2"
              style={{ borderBottom: "none" }}
            >
              <div className="toggleboxhead">
                <h2>Number of WFH allowed in a month</h2>
                <div className="attendace_Checkarea rigthsty ">
                  <div
                    className="inputbox lateselect inputbocandh2"
                    style={{ textAlign: "right" }}
                  >
                    <input
                      type="text"
                      style={{ marginRight: "3%" }}
                      className="custominputprofile"
                      autoComplete="off"
                      name="numberOfWFhallowed"
                      id="unplannedLeaves"
                      
                      value={values.numberOfWFhallowed}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                    />
                    <h2>Days</h2>
                  </div>
                </div>
              </div>
              {touched.numberOfWFhallowed && errors.numberOfWFhallowed && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.numberOfWFhallowed}
                </p>
              )}
            </div>

            <div className="toggleboxarea mb-2">
              <div className="attendace_Checkarea">
                <div
                  className="inputbox lateselect inputboxleave wfhinputbox"
                  style={{ textAlign: "unset", marginLeft: "3%" }}
                >
                  <h2 style={{ marginRight: "1%" }}>Effective After</h2>
                  <input
                    style={{ marginRight: "1%" }}
                    type="text"
                    className="custominputprofile"
                    autoComplete="off"
                    name="monthName"
                    id="monthName"
                    value={values.monthName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^\d]/g,
                        ""
                      );
                    }}
                  />

                  <h2>Months from date of joining</h2>
                </div>
              </div>
              {touched.monthName && errors.monthName && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.monthName}
                </p>
              )}
            </div>
          </>
        )} */}

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>
              What is the eligibility criteria for maternity leave after
              joining?
            </h2>
            {/* <p>
              A Female candidate will be eligible for maternity leaves after
            </p> */}
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  autoComplete="off"
                  name="maternityLeaves"
                  id="maternityLeaves"
                  // placeholder="INV"
                  value={values.maternityLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />
                <h2>Month</h2>
                <span></span>
              </div>
            </div>
          </div>
          {touched.maternityLeaves && errors.maternityLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.maternityLeaves}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>
              The maximum number of leaves allowed to be taken at one time.
            </h2>
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ marginRight: "3%" }}
                  type="text"
                  className="custominputprofile"
                  autoComplete="off"
                  name="maxLeaves"
                  id="maxLeaves"
                  // placeholder="INV"
                  value={values.maxLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />

                <span></span>
              </div>
            </div>
          </div>
          {touched.maxLeaves && errors.maxLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.maxLeaves}
            </p>
          )}
        </div>
        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>
              Reset Leaves
              <Tooltip
                title="Adjusts and carries forward previous year's leaves as per company policies."
                placement="top"
              >
                <IconButton>
                  <FaInfoCircle size="0.5em" />
                </IconButton>
              </Tooltip>
            </h2>
            <div className=" ">
              <div className="inputbox" style={{ textAlign: "right" }}>
                <select
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  name="resetLeave"
                  id="resetLeave"
                  value={values.resetLeave}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Month
                  </option>

                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>
              </div>
            </div>
          </div>
          {touched.resetLeave && errors.resetLeave && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.resetLeave}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Can employees take comp off?</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) => {
                      setFieldValue("compofff", e.target.value);
                    }}
                    checked={values.compofff === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("compofff", e.target.value);
                    }}
                    checked={values.compofff === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.compofff && errors.compofff && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}

          {/* comp off  */}
          {values.compofff == "1" && (
            <>
              <div className=" mb-2 mt-2" style={{ borderBottom: "none" }}>
                <div className="toggleboxhead">
                  <h2>
                    The hours beyond the shift timing after which comp off can
                    be claimed.
                  </h2>
                  <div className="attendace_Checkarea rigthsty ">
                    <div className="inputbox" style={{ textAlign: "right" }}>
                      <select
                        style={{ marginRight: "3%" }}
                        className="custominputprofile"
                        name="hours"
                        value={values.hours}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Hour
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                    </div>
                  </div>
                </div>
                {touched.hours && errors.hours && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.hours}
                  </p>
                )}
              </div>

              <div className=" mb-2" style={{ borderBottom: "none" }}>
                <div className="toggleboxhead">
                  <h2>
                    The maximum number of days allowed to use comp off after
                    approval.
                  </h2>
                  <div className=" ">
                    <div className="inputbox" style={{ textAlign: "right" }}>
                      <select
                        style={{ marginRight: "3%" }}
                        className="custominputprofile"
                        name="exp_time"
                        id="exp_time"
                        value={values.exp_time}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Month
                        </option>
                        <option value="current_month">Current month</option>
                        <option value="15Days">15 days</option>
                        <option value="1Month">1 month</option>
                        <option value="3Month">3 month</option>
                        <option value="6Month">6 month</option>
                        <option value="6Month">None</option>
                      </select>
                    </div>
                  </div>
                </div>
                {touched.exp_time && errors.exp_time && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.exp_time}
                  </p>
                )}
              </div>
            </>
          )}
          {/* comp off  */}
        </div>

        {/* <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Is Clubbing of leaves allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) => {
                      setFieldValue("clubbing_leaves", e.target.value);
                    }}
                    checked={values.clubbing_leaves === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("clubbing_leaves", e.target.value);
                    }}
                    checked={values.clubbing_leaves === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.clubbing_leaves && errors.clubbing_leaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}

        
          {values.clubbing_leaves === "1" && (
            <div className="toggleboxhead" style={{ marginTop: "40px" }}>
              <h2>Select leave type</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value={CasualleaveValue}
                      onChange={(e) => {
                        setFieldValue("leave_type", e.target.value);
                      }}
                      checked={values.leave_type === CasualleaveValue}
                    />
                  </label>
                  <span>Casual</span>
                </div>

                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value={EarnedleaveValue}
                      onChange={(e) => {
                        setFieldValue("leave_type", e.target.value);
                      }}
                      checked={values.leave_type === EarnedleaveValue}
                    />
                  </label>
                  <span>Earned</span>
                </div>
              </div>
              {touched.leave_type && errors.leave_type && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.leave_type}
                </p>
              )}
            </div>
          )}


          {values.clubbing_leaves === "1" &&
            values.leave_type &&
            values.formatOfCreditLeaves === "number" && (
              <>
                <div className=" mb-2 mt-2" style={{ borderBottom: "none" }}>
                  <div className="toggleboxhead">
                    <h2>The number of clubbed leaves allowed (in number)</h2>
                    <div className="attendace_Checkarea rightsty">
                      <div
                        className="inputbox lateselect inputbocandh2"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="text"
                          style={{ marginRight: "3%" }}
                          className="custominputprofile"
                          autoComplete="off"
                          name="max_ClubbedLeaves"
                          id="max_ClubbedLeaves"
                          // placeholder="INV"
                          value={values.max_ClubbedLeaves}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={(e) => {
                            e.target.value = (e.target.value || "").replace(
                              /[^0-9%]/g,
                              ""
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {touched.max_ClubbedLeaves && errors.max_ClubbedLeaves && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.max_ClubbedLeaves}
                    </p>
                  )}
                </div>
              </>
            )}
       
        </div> */}

        <div className="toggleboxarea mb-2" style={{ borderBottom: "none" }}>
          <div className="leaveDistributedsty">
            <h2>
              How will employee get the leaves distributed on
              <Tooltip
                title="It defines how leaves are given monthly or yearly as per company policy."
                placement="top"
              >
                <IconButton>
                  <FaInfoCircle size="0.5em" />
                </IconButton>
              </Tooltip>
            </h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="Monthly"
                    checked={values.leavedistributed === "Monthly"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  Each month, starting from the date of joining.
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="Monthly_JoinDate"
                    checked={values.leavedistributed === "Monthly_JoinDate"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  Distributed without considering the date of joining.
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="No_Monthly"
                    checked={values.leavedistributed === "No_Monthly"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  On the first day of joining, the employee will receive all
                  leaves for the year (without distribution).
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="No_Monthly_JoinDate"
                    checked={values.leavedistributed === "No_Monthly_JoinDate"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  On the date of joining, the employee will receive monthly
                  leaves throughout the year (with distribution).
                </p>
              </div>
            </div>
          </div>

          {touched.leavedistributed && errors.leavedistributed && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.leavedistributed}
            </p>
          )}
        </div>

        {/* {(values.leavedistributed === "Monthly" ||
          values.leavedistributed === "Monthly_JoinDate") && (
            <>
              <div className="toggleboxarea mb-2" style={{ border: "none;" }}>
                <div className="toggleboxhead">
                  <h2>Set leave credit date for monthly balance</h2>
                  <div className="attendace_Checkarea">
                    <div
                      className="inputbox lateselect"
                      style={{ textAlign: "right", width: "74%" }}
                    >
                      <label htmlFor="" className="custominputprofile">
                        <select
                          name="leaveCreditDate"
                          type="text"
                          className="custominputprofile"
                          id="leaveCreditDate"
                          // placeholder="INV"
                          value={values.leavecredit}
                          onChange={(e) => {
                            setFieldValue("leavecredit", e.target.value);
                          }}
                        >
                          <option value="" label="Day" />
                          {[...Array(31)].map((_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </label>

                      <span></span>
                    </div>
                  </div>
                </div>
                {touched.leavecredit && errors.leavecredit && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.leavecredit}
                  </p>
                )}
              </div>
            </>
          )} */}
      </div>
      <div className="butnsec">
        <button className="cusbtn" type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </form>
  );
};

export default Leave_Settings;
